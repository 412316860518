const variantName = 'heroV6'

export default {
  [variantName]: {
    hero: {
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      flexDirection: ['column', '', '', ''],
      height: ['', '', '100vh'],

      // TODO: Give Slider A Wrapper Variant and then put slick styles in there instead of in container
      '.slick-slider': {
        width: ['100%', '', '100%'],
        height: ['', '', '100vh']
      },

      '.slick-dots': {
        width: 'fit-content',
        left: '1rem',
        bottom: '1rem'
      },

      '.slick-dots li.slick-active button:before, .slick-dots li button:before': {
        color: 'white'
      },

      '.slick-prev, .slick-next': {
        right: 'unset',
        top: 'unset',
        left: '3rem',
        bottom: '2rem',
        width: '30px',
        zIndex: '10',
        color: 'white'
      },

      '.slick-prev': {
        left: '1rem'
      },
      '.slick-prev:hover, .slick-prev:focus, .slick-next:hover, .slick-next:focus': {
        color: 'inherit',
        opacity: '0.8'
      }
    },

    // ?============================
    // ?========  Images  ==========
    // ?============================

    image: {
      width: '100%',
      objectFit: 'cover',
      height: ['50vh', '', '100vh'],
      filter: ['', '', 'brightness(0.5)']
    },

    // ?============================
    // ?======  Box Content ========
    // ?============================

    content: {
      position: ['', '', 'absolute'],
      top: ['', '', '50%'],
      left: ['', '', '50%'],
      width: ['', '', '90%'],
      transform: ['', '', 'translate(-50%,-50%)'],
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: ['flex-start', '', 'center'],
      border: '1px solid',
      borderColor: 'primary',
      margin: '1rem 0.5rem',
      padding: ['1rem 1rem 2rem', '', '', '2rem'],
      maxHeight: ['', '', '90vh'],
      '.gatsby-link': {
        alignSelf: 'flex-end'
      }
    },

    // ?============================
    // ?=========  Logos  ==========
    // ?============================

    logoHero: {
      maxHeight: ['175px', '', '200px', '250px'],
      marginBottom: 3,
      display: ['none', '', 'block'],
      filter: ['', '', 'brightness(0) invert(1)']
    },

    // ?============================
    // ?======  Text Content =======
    // ?============================

    title: {
      fontSize: '1.75rem',
      marginBottom: 3,
      display: 'flex',
      flexDirection: 'column',
      color: ['', '', 'primary'],
      // ?== Psuedo Elements ==
      '&::after': {
        content: '""',
        position: 'relative',
        left: '0px',
        width: '100%',
        height: '2px',
        marginTop: '0.5rem',
        backgroundColor: 'white',
        opacity: '0.7'
      }
    },
    subtitle: {
      fontSize: '1.5rem',
      fontWeight: 'normal',
      marginBottom: '1rem'
    },
    text: {},

    // ?============================
    // ?========  Buttons  =========
    // ?============================

    ctaButton: {
      display: 'flex',
      marginTop: [4, '', 1],
      marginRight: '0.5rem',
      borderBottom: '2px solid',
      paddingBottom: '0.5rem',
      fontSize: '1.5rem',
      fontWeight: 'bold',
      height: '100%',
      '&:hover': {
        color: 'secondary'
      }
    },

    // ?============================
    // ?=======  Hero Shout  =======
    // ?============================

    shoutWidget: {
      container: {
        marginTop: '1rem',
        width: '100%',
        alignItems: 'stretch',
        backgroundColor: ['', '', 'rgb(138 138 138 / 15%)'],
        alignSelf: ['flex-start', '', 'center'],
        textAlign: 'center',
        maxWidth: '767px',
        display: 'flex',
        padding: ['', '', '0.5rem;'],
        border: 'solid 1px #aeaeae'
      },
      content: {
        order: '2',
        flexDirection: 'column',
        justifyContent: 'center',
        flexGrow: '1',
        paddingLeft: [2, 2, 4],
        paddingRight: 1,
        paddingY: 1,
        width: '80%',
        color: 'text',
        textAlign: 'left'
      },
      title: {
        fontSize: 3,
        textTransform: 'uppercase',
        '&::after': {
          content: '""',
          position: 'relative',
          width: ['100%', '', '100px'],
          height: '2px',
          marginTop: '0.5rem',
          backgroundColor: 'white',
          opacity: '0.7'
        },
        display: 'flex',
        flexDirection: 'column',
        alignItems: ['', '', 'center']
      },
      text: {
        marginY: 1,
        fontWeight: '400',
        color: ['primary', '', 'light'],
        fontSize: ['1.1rem', '1.25rem', '1.5rem']
      },
      shoutCTAS: { flexWrap: 'wrap', marginBottom: '0.5rem' },
      shoutCTA: {
        margin: '0.5rem 1rem 0.5rem 0rem',
        textDecoration: 'underline',
        fontSize: ['1.25rem', '1.5rem'],
        ':hover': {
          color: 'primary'
        }
      },
      date: {
        fontSize: 2,
        color: '#e0e0e0',
        fontWeight: 'bold'
      },
      imageContainer: {
        order: '1',
        width: '40%',
        position: 'relative',
        top: ['', '', '-20px'],
        maxHeight: ['', '', '100%'],
        img: {
          borderRadius: ['0 10px 10px 0', '', '0px'],
          width: '100%',
          height: ['100%', '', '', ''],
          objectFit: 'cover',
          boxShadow: ['', '', '', '2px 2px 8px black']
        }
      }
    }
  }
}
