const variantName = 'generalPage'

export default {
  [variantName]: {
    // ?============================
    // ?======  Containers =========
    // ?============================

    container: {
      maxWidth: '680px',
      margin: '0 auto',
      padding: '1rem'
    },

    title: {
      fontSize: ['2.5rem', '2.75rem', '3rem'],
      marginBottom: '1rem'
    },
    subtitle: {
      marginBottom: '1rem'
    },
    text: {},

    timeContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      borderBottom: '1px solid',
      opacity: '0.6',
      padding: '0.5rem 0rem 0rem',
      margin: 'auto 0rem 2rem',
      width: '100%'
    },

    readingTime: {
      fontSize: '0.8em',
      margin: '0rem 1rem 0rem 0rem',
      padding: '0.5rem 0rem'
    },

    createdAt: {
      fontSize: '0.8em',
      margin: '0rem 1rem 0rem 0rem',
      padding: '0.5rem 0rem'
    },

    imageContainer: {
      marginBottom: '1rem',
      width: '100%'
    },

    image: {
      width: '100%'
    },

    bodyContainer: {
      h1: {
        margin: '0rem 0rem 1rem'
      },
      h2: {
        margin: '0rem 0rem 1rem'
      },
      h3: {
        margin: '0rem 0rem 1rem'
      },
      h4: {
        margin: '0rem 0rem 1rem'
      },
      h5: {
        margin: '0rem 0rem 1rem'
      },
      h6: {
        margin: '0rem 0rem 1rem'
      },
      p: {
        lineHeight: '1.5',
        margin: '0rem 0rem 1rem'
      },
      ul: {
        margin: '0rem 0rem 1rem'
      },
      ol: {
        margin: '0rem 0rem 1rem'
      },
      li: {
        lineHeight: '1.5',
        marginBottom: '0.5rem'
      }
    },

    // ?============================
    // ?========  Buttons  =========
    // ?============================

    ctaButton: {}
  }
}
