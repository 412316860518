const variantName = 'ctaWidgetV1a'

export default {
  [variantName]: {
    // ?============================
    // ?======  Containers =========
    // ?============================

    container: {
      position: 'fixed',
      flexDirection: ['', '', 'column'],
      flexWrap: 'wrap',
      bottom: ['0px', '', '0.5rem'],
      right: ['', '', '0.5rem'],
      width: ['100%', '', 'unset'],
      border: ['', '', 'none'],
      zIndex: '1000',

      // ?============================
      // ?========  Buttons  =========
      // ?============================

      // TODO: change a to a variantName and give it is own variant rather than nested
      a: {
        backgroundColor: 'primary',
        minWidth: '50%',
        flexGrow: '1',
        margin: ['', '', '0.25rem 0'],
        padding: ['0.75rem', '', '1rem'],
        justifyContent: 'center',
        textAlign: 'center',
        textTransform: 'uppercase',
        fontSize: [2, 2, 2, 2],
        borderRadius: ['', '', '300px'],
        transition: 'all ease-in-out 0.5s',
        boxShadow: ['', '', '2px 2px 8px grey'],
        border: ['solid 1px'],
        borderColor: 'light',

        // ?== Nested Elements ==
        svg: {
          marginRight: ['0.5rem', '', 'unset']
        },

        // ?== Psuedo Elements ==
        '&:hover': {
          backgroundColor: 'secondary'
        },
        ':last-child': {}
      }
    },
    ctaLabel: {
      display: ['', '', 'none'] // *hides text of label on tablet and just shows the icon
    }
  }
}
