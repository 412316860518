const variantName = 'navigationV4'

export default {
  [variantName]: {
    // ?============================
    // ?======== Container =========
    // ?============================

    container: {
      position: 'fixed',
      width: '100%',
      top: '0px',
      left: '0px',
      zIndex: '1000',
      boxSizing: 'border-box',
      // height: ['75px', '75px', '75px', 'unset'],
      padding: ['0.5rem', '', '', '2rem 1rem'],
      display: 'flex',
      transition: 'all ease-in-out 0.5s',
      alignItems: 'center',
      justifyContent: 'flex-end',
      background: 'linear-gradient(180deg, rgba(0,0,0,0.75) 0%, rgba(0,0,0,0.33) 50%, rgba(255,255,255,0) 100%)',
      color: 'light',
      '.seperatorLine': {
        margin: '1rem auto 2rem',
        width: '90%',
        maxWidth: '600px'
      }
    },
    containerScrolled: {
      variant: `${variantName}.container`,
      height: ['75px', '75px', '75px', 'unset'],
      padding: ['0.5rem', '', '', '0.75rem']
    },

    // ?============================
    // ?=======  Nav Logos =========
    // ?============================

    logoLocationContainer: {
      flexDirection: 'column',
      alignItems: 'center',
      padding: '0.25rem 1rem',
      height: '100%',
      position: ['', '', '', 'absolute'],
      left: ['', '', '', '50%'],
      transform: ['', '', '', 'translateX(-50%)'],
      textAlign: 'center',
      display: ['inline-block', '', '', '']
    },

    logo: {
      marginBottom: '0.5rem',
      zIndex: '2000',
      transition: 'all ease-in-out 0.4s',
      justifyContent: 'center',
      img: {
        maxHeight: '90px'
      }
    },
    logoScrolled: {
      variant: `${variantName}.logo`,
      maxHeight: ['100%'],
      height: ['100%'],
      display: ['inline-block', '', '', 'inline'],
      textAlign: 'left',
      img: {
        height: ['100%'],
        maxHeight: ['100%', '100px'],
        padding: 0
      }
    },

    navMenuLogo: {
      img: {
        maxHeight: '120px'
      },
      marginBottom: '1rem'
    },

    // ?============================
    // ?=======  Hamburger =========
    // ?============================

    hamburger: {
      position: 'relative',
      display: ['flex', 'flex', 'flex', ''],
      zIndex: '2000',
      alignSelf: 'center',
      flexDirection: 'column',
      justifyContent: 'space-between',
      width: '35px',
      height: '25px',
      cursor: 'pointer',
      margin: ['0 0.5rem 0 auto', '', '', '0rem 0.75rem'],
      minWidth: '30px',
      div: {
        alignSelf: 'flex-end',
        transition: 'all ease-in-out 0.3s',
        height: '4px',
        width: '100%',
        backgroundColor: 'secondary',
        borderRadius: '10px'
      }
    },
    hamburgerOpen: {
      variant: `${variantName}.hamburger`,
      height: 'unset',
      div: {
        position: 'absolute',
        boxShadow: 'none',
        transition: 'all ease-in-out 0.3s',
        height: '4px',
        width: '100%',
        backgroundColor: 'secondary',
        borderRadius: '10px'
      },
      '& > div:nth-of-type(1)': {
        transform: 'rotate(135deg) '
      },
      '& > div:nth-of-type(2)': {
        transform: 'rotate(-135deg)'
      }
    },

    // ?============================
    // ?========  Nav Menu =========
    // ?============================

    navMenu: {
      position: ['absolute', 'absolute', 'absolute'],
      margin: '0px',
      boxSizing: 'border-box',
      justifyContent: ['center', 'center', 'center'],
      backgroundColor: ['primary', 'primary', 'primary'],
      top: '0px',
      zIndex: '1000',
      width: ['100%', '100%', '100%', '100%'],
      height: '100vh',
      minWidth: '250px',
      left: '-120%',
      transition: 'all ease-in-out 0.5s',
      padding: ['0.5rem'],
      alignItems: ['center', 'center', 'center', 'center'],
      textAlign: 'center',
      display: 'flex',
      flexDirection: ['column', 'column', 'column'],
      overflow: 'hidden',
      listStyleType: 'none'
    },

    // when nav is open it uses this variant
    navMenuOpen: {
      variant: `${variantName}.navMenu`,
      left: '0%',
      '.navItemDropdownMenu': {
        position: ['', '', '', 'static'],
        opacity: '0.9',
        '.navItem a': { fontSize: ['1rem', '', '', '0.7rem', '0.9rem'] }
      }
    },

    navItem: {
      margin: ['0.5rem', '0.75rem', '', '0.5rem'],
      zIndex: '100',
      listStyleType: 'none',
      a: {
        fontSize: ['1.2rem', '', '', '0.8rem', '1rem'],
        textTransform: 'uppercase',
        fontWeight: '400',
        letterSpacing: '2px',
        textDecoration: 'none',
        ':hover': {
          color: 'secondary'
        }
      }
    },

    smallNavMenu: {
      display: ['none', '', '', 'flex']
    },

    smallNavHeading: {
      fontSize: '1rem',
      marginBottom: '0.5rem',
      fontWeight: '400'
    },

    // ?==========================
    // ?====  Dropdown Styles ====
    // ?==========================

    navItemDropdown: {
      alignItems: 'inherit',
      fontFamily: 'inherit',
      justifyContent: 'inherit',
      position: 'relative',
      cursor: 'pointer'
    },

    subNavItemDropdown: {
      variant: `${variantName}.navItem`,
      padding: '0.5rem',
      margin: ['0rem', '0rem', '0rem', '0rem'],
      whiteSpace: 'nowrap'
    },

    navItemDropdownMenu: {
      padding: '0.5rem',
      textShadow: '0px 0px 30px rgb(122 122 122 / 79%)',
      position: ['', '', '', 'absolute'],
      display: 'flex',
      alignItems: 'inherit',
      justifyContent: 'inherit',
      flexDirection: 'column',
      width: 'fit-content',
      backgroundColor: 'primary'
    },

    // ?==========================
    // ?=====  Nav Widgets  ======
    // ?==========================

    locationSwitcher: {
      backgroundColor: '',
      zIndex: '2000',
      alignSelf: 'flex-start',
      button: {
        backgroundColor: 'unset',
        borderTop: '1px solid',
        borderRadius: '0px'
      },
      '.popUp': {
        transform: ['', '', '', 'translateX(-45%)']
      },
      '.popUp .content': {
        backgroundColor: 'rgb(255 255 255 / 74%)'
      }
    },

    socialIconsContainerDesktop: {
      display: ['none', '', '', 'flex'],
      padding: '0.5rem 1rem',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
      margin: ['', '', '', '0 auto 0 0'],
      a: {
        display: 'flex',
        textAlign: 'center',
        padding: '0.5rem',
        alignItems: 'center',
        transition: 'all ease-in-out 0.5s',
        ':hover': {
          transform: 'scale(1.5)',
          svg: {
            path: { fill: 'secondary' }
          }
        }
      }
    },

    phoneContainerDesktop: {
      display: ['none', '', '', 'block'],
      borderRight: 'solid 1px',
      borderColor: 'secondary',
      paddingRight: '0.75rem',
      a: {
        transition: 'all ease-in-out 0.5s',
        ':hover': {
          color: 'secondary',
          fontSize: '1.1rem'
        }
      }
    },

    phoneSocialContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
      width: '100%',
      maxWidth: '450px'
    },

    phoneContainer: {
      padding: '0.5rem',
      width: '50%',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
      borderRight: '2px solid',
      borderColor: 'secondary',
      a: {
        display: 'flex',
        padding: '0.5rem',
        alignItems: 'center',
        fontSize: '0.8rem',
        textAlign: 'center',
        justifyContent: 'center',
        svg: {
          marginRight: '0.25rem'
        }
      }
    },

    socialIconsContainer: {
      padding: '0.5rem',
      flexDirection: 'column',
      alignItems: 'center',
      width: '50%',
      textAlign: 'center',
      a: {
        display: 'flex',
        textAlign: 'center',
        padding: '0.5rem',
        alignItems: 'center'
      }
    }
  }
}
