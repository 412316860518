export default {
  titleBlockV1a: {
    container: {
      textAlign: 'center',
      color: 'primary',
      paddingX: 3,
      paddingY: 8
    },
    title: {
      fontWeight: '400'
    }
  }
}
