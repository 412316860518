export default {
  sideBySideV3: {
    container: {
      flexDirection: ['column', 'column', 'row', 'row'],
      alignItems: ['', '', '', 'center'],
      padding: '1rem',
      justifyContent: 'center'
    },
    content: {
      flexDirection: 'column',
      padding: ['0rem 1rem', '', '', 4],
      width: ['100%', '100%', 'unset'],
      justifyContent: 'center',
      textAlign: 'center',
      alignItems: 'center',
      borderRight: ['', '', '2px solid'],
      borderColor: 'light',
      a: {
        display: 'flex',
        width: 'fit-content'
      }
    },
    subtitle: {
      fontWeight: 6,
      marginBottom: 3
    },
    title: {
      fontSize: 6,
      marginBottom: 3
    },

    text: {
      lineHeight: '1.75',
      whiteSpace: 'break-spaces'
    },
    ctaContainer: {
      width: ['100%', '100%', 'auto'],
      justifyContent: 'center',
      padding: ['0.5rem', '', '1rem'],
      a: {
        margin: '0rem',
        display: 'flex',
        width: ['', '', '100%'],
        span: {
          marginTop: ['', '', '0rem']
        }
      }
    },

    ctaButton: {
      padding: 3,
      display: 'flex',
      alignItems: 'center',
      marginTop: 5,
      border: 'solid 2px',
      borderColor: 'primary',
      color: 'primary',
      width: 'max-content',
      textTransform: 'uppercase',
      fontWeight: 'bold',
      textAlign: 'center',
      '&:hover': {
        backgroundColor: 'primary',
        color: 'text'
      }
    }
  }
}
