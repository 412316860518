const variantName = 'contentSectionV2a'

export default {
  [variantName]: {
    // ?============================
    // ?======  Containers =========
    // ?============================

    container: {
      whiteSpace: 'break-spaces',
      color: 'light',
      backgroundPosition: 'center',
      backgroundSize: 'inherit',
      backgroundRepeat: 'no-repeat'
    },
    innerContainer: {
      height: '100vh'
    },
    section: {
      maxWidth: '730px',
      flexDirection: 'column',
      textAlign: ['left', '', ''],
      alignItems: ['flex-start'],
      justifyContent: ['center', '', 'flex-end'],
      padding: '1rem',
      margin: ['', '', '0rem 0rem 4rem 2rem']
    },

    // ?============================
    // ?======  Text Content =======
    // ?============================

    title: {
      marginBottom: 3,
      fontSize: ['2.5rem', '', '', '4.5rem'],
      borderLeft: 'solid 3px',
      borderColor: 'secondary',
      paddingLeft: '1rem'
    },
    subtitle: {
      marginBottom: 3,
      fontSize: 4,
      fontWeight: 'normal'
    },
    text: {
      zIndex: '100',
      fontSize: ['1rem', '', '', '1.5rem'],
      lineHeight: '1.75',
      color: 'light',
      margin: '0rem 0rem 1rem',
      whiteSpace: 'break-spaces'
    },

    // ?============================
    // ?========  Buttons  =========
    // ?============================

    ctaButton: {
      variant: 'buttons.primary'
    },
    secondaryCtaButton: {
      variant: 'buttons.secondary'
    }
  }
}
