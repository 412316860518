export default {
  sideBySideV1g: {
    container: {
      backgroundColor: 'background',
      flexDirection: ['column', '', '', 'row'],
      alignItems: ['', '', '', 'stretch'],
      position: 'relative',
      padding: ['1rem', '', '', '10vw 2rem 8vh'],
      '.lazyload-wrapper': {
        width: ['100%', '', '', '50%']
      }
    },
    content: {
      flexDirection: 'column',
      padding: [4, '', '', 8],
      width: ['100%', '', '', '50%'],
      position: ['', '', '', 'relative'],
      right: ['', '', '', '5%'],
      justifyContent: 'center',
      zIndex: '100',
      backgroundColor: 'background',
      a: {
        display: 'flex',
        width: 'fit-content'
      },
      order: ['', '', '', '2']
    },
    subtitle: {
      fontWeight: 6,
      marginBottom: 3
    },
    title: {
      fontSize: 6,
      marginBottom: 3
    },

    text: {
      lineHeight: '1.75'
      // whiteSpace: 'break-spaces',
    },

    image: {
      width: ['100%', '', '', '100%'],
      height: '100%',
      order: ['', '', '', '1'],
      position: ['', '', '', 'relative'],
      top: ['', '', '', '-5vw'],
      right: ['', '', '', '-5%'],
      objectFit: 'cover'
    },

    // ?============================
    // ?========  Buttons  =========
    // ?============================

    ctaButton: {
      variant: 'buttons.primary'
    },
    secondaryCtaButton: {
      variant: 'buttons.secondary'
    }
  }
}
