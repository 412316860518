const variantName = 'shoutSectionV2b'

export default {
  [variantName]: {
    container: {
      width: '100%',
      flexDirection: ['column', 'row'],
      fontSize: [4, 4, 5, 6],
      justifyContent: 'center'
    },
    content: {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: ['flex-start', 'flex-start', 'flex-start'],
      padding: '4rem 2rem',
      color: 'text',
      width: ['100%', '70%', '50%'],
      flexGrow: '1',
      order: ['1', '', '2']
    },
    title: {
      textAlign: ['left', 'left', 'left'],
      width: ['100%', '', 'fit-content'],
      fontSize: '1.5rem',
      borderBottom: ['2px solid', '', ''],
      borderColor: 'secondary',
      padding: '0.25rem 0rem',
      marginBottom: '0.5rem',
      color: 'secondary',
      fontWeight: 'normal'
    },
    text: {
      textAlign: ['left', 'left', 'left'],
      margin: '0 auto',
      fontSize: ['1.25rem', '1.25rem', ' 1.5rem'],
      width: '100%'
      // fontWeight: 'bold',
    },
    date: {
      textAlign: ['left', 'left', 'left'],
      margin: '0.5rem auto 0rem',
      width: '100%',
      order: '3',
      opacity: '0.8',
      fontSize: ['0.8rem', '0.9rem']
    },
    imageContainer: {
      position: 'relative',
      width: ['100%', '30%', '20%'],
      flexGrow: '1',
      order: ['2', '', '1'],
      img: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        objectFit: 'cover'
      }
    },
    imageFill: {
      paddingBottom: '100%'
    },
    shoutCTAS: {
      flexWrap: 'wrap',
      marginBottom: '0.5rem',
      transition: 'all ease-in-out 0.3s'
    },
    shoutCTA: {
      variant: 'buttons.primary',
      margin: '0.5rem 1rem 0.5rem 0rem'
    }
  }
}
