export default {
  multiLocationsV1: {
    container: {
      minHeight: '100vh',
      width: '100vw',
      position: 'relative',
      flexDirection: 'column',
      justifyContent: 'center',
      a: {
        textDecoration: 'none;'
      }
    },
    logo: {
      position: ['static', 'static', 'absolute'],
      display: ['flex'],
      justifyContent: 'center',
      top: '0',
      left: '50%',
      height: '150px',
      transform: ['none', 'none', 'translateX(-50%)'],
      width: '100%',
      img: {
        maxWidth: '100%',
        maxHeight: '150px'
      }
    },
    locationsContainer: {
      flexDirection: ['column', 'column', 'row'],
      flexGrow: '1',
      flexWrap: ['wrap', 'wrap', 'wrap-reverse']
    },
    location: {
      flexGrow: '1',
      padding: '0.75rem 0.5rem',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundPosition: 'center',
      backgroundSize: 'cover'
    },
    content: {
      textAlign: 'center',
      flexDirection: 'column'
    },
    locationTitle: {
      color: 'light',
      fontWeight: 'bold',
      textTransform: 'uppercase'
    },
    CTAS: {
      flexWrap: 'wrap',
      justifyContent: 'center'
    },

    CTAButton: {
      variant: 'buttons.multiLocation',
      width: 'calc(50% - 32px)',
      margin: '16px',
      svg: {
        marginRight: '5px'
      }
    },
    enterSiteButton: {
      margin: '0 16px',
      border: 'solid 1px white',
      backgroundColor: 'unset',
      borderRadius: '5px',
      color: 'white',
      display: 'flex',
      textDecoration: 'none',
      variant: 'buttons.multiLocation'
    }
  }
}
