export default {
  text: '#0c3450',
  primary: '#001f3e',
  secondary: '#eb3d3c',
  brandGrey: '#566464',
  background: 'white',
  backgroundSecondary: '#202020',
  light: '#FFF',
  dark: '#020303'
}
