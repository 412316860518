export default {
  shoutWidgetV1: {
    container: {
      width: '100%',
      alignItems: 'stretch',
      backgroundColor: 'background',
      padding: 0,
      maxWidth: '500px',
      display: 'flex'
    },
    content: {
      order: '2',
      flexDirection: 'column',
      justifyContent: 'center',
      flexGrow: '1',
      paddingLeft: 2,
      paddingRight: 1,
      paddingY: 1,
      width: '80%',
      color: 'text'
    },
    title: {
      fontSize: 3,
      fontWeight: 'bold',
      color: 'secondary'
    },
    text: {
      fontSize: '0.8rem',
      marginY: 1
    },
    date: {
      fontSize: 2,
      color: 'secondary',
      fontWeight: 'bold'
    },
    imageContainer: {
      order: '1',
      width: '35%',
      img: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        border: 'solid 3px',
        borderColor: 'secondary'
      }
    }
  }
}
