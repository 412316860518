const variantName = 'galleryV1b'

export default {
  [variantName]: {
    // ?============================
    // ?======  Containers =========
    // ?============================
    container: {
      padding: '3rem 1rem',
      width: '100%'
    },
    wrapper: {
      width: '100%'
    },

    // ?============================
    // ?====  Albums Containers ====
    // ?============================

    albumsContainer: {
      width: '100%',
      flexWrap: 'wrap',
      justifyContent: 'center',
      // ?== Psuedo Elements ==
      // ! some of this masonary styling is in the component
      // ! may be needed to reworked to be moved into style files
      'div:nth-of-type(5n+1),div:nth-of-type(5n+2)': {
        width: ['100%', '50%', '50%,']
      }
    },
    albumsCTA: {
      width: ['100%', '33.333%', '33.333%,'],
      margin: ['0rem', ''],
      height: '300px',
      cursor: 'pointer',
      alignItems: 'flex-end',
      justifyContent: 'flex-start',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundColor: 'primary',
      position: 'relative',
      // ?== Psuedo Elements ==
      '::before, ::after': {
        transition: 'all ease-in-out 0.4s',
        opacity: '0'
      },
      ':hover': {
        '.albumTitle': {
          marginBottom: '3rem'
        },
        '::before': {
          content: '""',
          left: '0rem',
          top: '0rem',
          width: '100%',
          height: '100%',
          position: 'absolute',
          backgroundColor: 'secondary',
          opacity: '0.5',
          display: 'flex',
          alignItems: 'flex-end',
          justifyContent: 'flex-end'
        },
        '::after': {
          opacity: '1'
        }
      }
    },
    albumTitle: {
      transition: 'all ease-in-out 0.4s',
      color: 'white',
      fontSize: '1.5rem',
      textAlign: 'left',
      marginBottom: '1rem',
      marginLeft: '1rem',
      zIndex: '500'
    },

    // ?============================
    // ?=====  Album Container =====
    // ?============================

    albumContainer: {
      flexWrap: 'wrap',
      alignItems: 'stretch',
      margin: '1rem auto',
      justifyContent: 'center',
      gridGap: 4,
      gridTemplateColumns: 'repeat( auto-fill, minmax( 300px, 1fr ) )',
      gridAutoRows: '100px',
      padding: 4,
      display: 'grid !important'
    },
    albumImage: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      padding: 1
    }
  }
}
