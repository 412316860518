export default {
  navItemDropdownV1: {
    navItemDropdown: {
      alignItems: 'inherit',
      fontFamily: 'inherit',
      justifyContent: 'inherit',
      position: 'relative'
    },

    subNavItemDropdown: {
      display: 'flex',
      alignItems: 'inherit',
      justifyContent: 'inherit',
      fontFamily: 'inherit'
    },
    navItemDropdownMenu: {
      padding: '0.5rem',
      textShadow: '0px 0px 30px rgb(122 122 122 / 79%)',
      position: ['', '', '', 'absolute'],
      display: 'flex',
      alignItems: 'inherit',
      justifyContent: 'inherit',
      flexDirection: 'column',
      width: '100%'
    }
  }
}
