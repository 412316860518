const variantName = 'eventsV1a'

export default {
  [variantName]: {
    // ?============================
    // ?======  Containers =========
    // ?============================
    container: {
      padding: '1rem'
    },
    // ?============================
    // ?======  Text Content =======
    // ?============================

    heading: {
      padding: '0.5rem;',
      textAlign: 'center',
      fontWeight: 'bold',
      fontSize: '2rem',
      textTransform: 'uppercase'
    },

    textContent: {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '2rem 1rem 1rem'
    },
    title: {
      fontSize: ['2rem'],
      marginBottom: '1rem'
    },
    subtitle: {
      marginBottom: '1rem'
    },
    text: {},

    // ?============================
    // ?===== Events Container =====
    // ?============================

    eventsContainer: {
      justifyContent: 'center',
      flexWrap: 'wrap'
    },

    // ?============================
    // ?======  Event Item  ========
    // ?============================
    eventItemContainer: {
      margin: ['2%'],
      width: ['100%', '46%', '29.333%', '21%'],
      flexDirection: 'column',
      border: 'solid 1px lightgrey',
      padding: '0.5rem',
      borderRadius: '10px'
    },
    eventItemImage: {
      minHeight: '250px',
      maxHeight: '250px',
      objectFit: 'cover'
    },
    eventItemContent: {
      flexGrow: '1',
      paddingTop: 2,
      paddingBottom: 2,
      flexDirection: ['column', 'row']
    },
    eventItemTitle: {
      fontSize: 4,
      textTransform: 'capitalize',
      textAlign: 'left',
      fontWeight: 'bolder',
      fontFamily: 'heading',
      color: 'text'
    },

    eventItemBtn: {
      variant: 'buttons.primary'
    },

    eventItemDateContainer: {
      textAlign: ['left', 'center'],
      maxWidth: '50px',
      maxHeight: '50px',
      minWidth: 'unset',
      marginRight: [0, 1]
    },
    eventDate: {
      fontSize: 3,
      color: 'primary',
      fontWeight: 'bolder',
      width: '100%',
      marginBottom: 0,
      fontFamily: 'body'
    },
    eventDaysContainer: {
      marginTop: '0.5rem'
    },
    eventDays: {
      textTransform: 'capitalize',
      color: 'primary',
      fontWeight: 'bold'
    },
    eventMonth: {
      textTransform: 'uppercase',
      marginBottom: 0,
      width: '100%',
      fontFamily: 'body'
    },

    // ?============================
    // ?======  Modal Styles =======
    // ?============================

    modalContainer: {
      position: 'fixed',
      top: '0px',
      bottom: '0px',
      left: '0px',
      right: '0px',
      zIndex: '9999'
    },
    modalBackground: {
      position: 'absolute',
      top: '0px',
      bottom: '0px',
      left: '0px',
      right: '0px',
      backgroundColor: 'rgba(0,0,0,0.5)',
      zIndex: '1000'
    },
    modal: {
      padding: '0px',
      maxWidth: '767px',
      zIndex: '9999',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)',
      backgroundColor: 'backgroundSecondary',
      minWidth: '280px',
      borderRadius: '10px',
      overflow: 'hidden',
      boxShadow: '2px 2px 8px dark',
      maxHeight: '95vh'
    },
    modalImage: {
      maxHeight: ['175px', '250px', '400px'],
      width: '100%',
      objectFit: 'cover'
    },

    modalContentContainer: {
      padding: 3,
      color: 'dark'
    },

    modalEventName: {
      fontFamily: 'heading',
      fontWeight: 'heading',
      lineHeight: 'heading',
      textTransform: 'capitalize',
      color: 'text',
      fontSize: 5,
      marginBottom: 3,
      textAlign: 'left'
    },
    modalEventDate: {
      alignItems: 'center',
      marginBottom: 3,
      color: 'dark'
    },
    modalEventTime: {
      marginBottom: '1rem'
    },

    datesSeperator: {
      color: 'dark',
      marginRight: 2,
      marginLeft: 1
    },
    modalEventDescription: {
      fontSize: '0.9rem'
    },

    eventCTAS: { flexWrap: 'wrap', marginBottom: '0.5rem' },
    eventCTA: {
      variant: 'buttons.primary',
      fontSize: ['1.1rem', '1.25rem'],
      cursor: 'pointer',
      // ?== Psuedo Elements ==
      ':hover': {
        color: 'primary'
      }
    }
  }
}
