export default {
  sectionNavLinksV1: {
    container: {
      width: '100%',
      backgroundColor: 'primary',
      flexWrap: 'wrap',
      justifyContent: 'center',
      paddingY: 9
    },

    box: {
      width: '140px',
      height: '140px',
      display: 'flex',
      justifyContent: 'center',
      borderColor: 'light',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderRadius: 3,
      margin: 0,
      alignItems: 'stretch',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      a: {
        width: '100%',
        textDecoration: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }
    },
    linkName: {
      color: 'light'
    }
  }
}
