const variantName = 'heroV5'

export default {
  [variantName]: {
    // ?============================
    // ?======  Containers =========
    // ?============================
    hero: {
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      flexDirection: ['column', '', '', 'row'],
      height: ['', '', '', '100vh'],

      // TODO: Give Slider A Wrapper Variant and then put slick styles in there instead of in container
      '.slick-slider': {
        width: ['100%', '', '', '75%'],
        height: ['', '', '', '100vh']
      },

      '.slick-dots': {
        width: 'fit-content',
        right: '1rem',
        bottom: '0rem'
      },

      '.slick-dots li.slick-active button:before, .slick-dots li button:before': {
        color: 'white'
      },

      '.slick-prev, .slick-next': {
        left: 'unset',
        top: 'unset',
        right: '1rem',
        bottom: '2rem',
        width: '30px',
        zIndex: '10',
        color: 'white'
      },

      '.slick-prev': {
        right: '3rem'
      },
      '.slick-prev:hover, .slick-prev:focus, .slick-next:hover, .slick-next:focus': {
        color: 'inherit',
        opacity: '0.8'
      }
    },

    // ?============================
    // ?========  Images  ==========
    // ?============================

    image: {
      width: '100%',
      objectFit: 'cover',
      height: ['50vh', '', '', '100vh']
    },

    // ?============================
    // ?======  Box Content ========
    // ?============================

    content: {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-start',
      padding: ['1rem 1rem 2rem', '', '', '1.5rem'],
      '.gatsby-link': {
        alignSelf: 'flex-end'
      }
    },

    // ?============================
    // ?=========  Logos  ==========
    // ?============================

    logoHero: {
      maxHeight: ['175px', '', '200px', '250px'],
      marginBottom: 3
    },

    // ?============================
    // ?======  Text Content =======
    // ?============================

    title: {
      fontSize: 7,
      marginBottom: 3
    },
    subtitle: {
      fontSize: 5,
      fontWeight: 'normal',
      marginBottom: 3,
      opacity: '0.8'
    },
    text: {},

    // ?============================
    // ?========  Buttons  =========
    // ?============================

    ctaButton: {
      display: 'flex',
      marginTop: '1rem',
      marginRight: '0.5rem',
      borderBottom: '2px solid',
      paddingBottom: '0.5rem',
      fontSize: '1.5rem',
      fontWeight: 'bold',
      '&:hover': {
        color: 'secondary'
      }
    },

    // ?============================
    // ?=======  Hero Shout  =======
    // ?============================

    shoutWidget: {
      container: {
        width: '100%',
        alignItems: 'stretch',
        backgroundColor: ['', '', 'rgba(121, 121, 121, 0.8)'],
        alignSelf: 'flex-start',
        maxWidth: '500px',
        display: 'flex',
        position: ['', '', 'absolute'],
        top: ['', '', '50vh', 'unset'],
        bottom: ['', '', '', '0.5rem'],
        transform: ['', '', 'translateY(-100%)', 'unset'],
        padding: ['', '', '0.5rem;'],
        left: ['', '', '', '0.5rem']
      },
      content: {
        order: '2',
        flexDirection: 'column',
        justifyContent: 'center',
        flexGrow: '1',
        paddingLeft: 2,
        paddingRight: 1,
        paddingY: 1,
        width: '80%',
        color: 'text'
      },
      title: {
        fontSize: 4,
        textTransform: 'uppercase',
        fontWeight: 'bold',
        '&::after': {
          content: '""',
          position: 'relative',
          left: '-30px',
          width: '75px',
          height: '2px',
          marginTop: '0.5rem',
          backgroundColor: 'white',
          opacity: '0.7'
        },
        display: 'flex',
        flexDirection: 'column'
      },
      text: {
        fontSize: '1.5rem',
        marginY: 1
      },
      date: {
        fontSize: 2,
        color: '#e0e0e0',
        fontWeight: 'bold'
      },
      imageContainer: {
        order: '1',
        width: '40%',
        position: 'relative',
        top: ['', '', '-35px'],
        maxHeight: ['', '', '150px'],
        img: {
          borderRadius: ['0 10px 10px 0', '', '0px'],
          width: '100%',
          height: ['100%', '', '', '170px'],
          objectFit: 'cover'
        }
      }
    }
  }
}
