const variantName = 'contentBoxesV1'

export default {
  [variantName]: {
    // ?============================
    // ?======  Containers =========
    // ?============================
    container: {
      padding: '3rem 1rem'
    },

    // ?============================
    // ?=========  Boxes  ==========
    // ?============================

    contentBoxes: {
      flexWrap: 'wrap',
      justifyContent: 'center',
      margin: '0 auto'
    },
    box: {
      padding: '1rem',
      display: 'flex',
      flexDirection: 'column',
      width: ['100%', '50%', '25%'],
      // ?== Psuedo Elements ==
      '& :last-child': {
        marginBottom: '0px'
      }
    },

    // ?============================
    // ?========  Images  ==========
    // ?============================

    image: {
      marginBottom: 1,
      width: '100%'
    },

    // ?============================
    // ?======  Text Content =======
    // ?============================

    date: {
      marginBottom: 1,
      fontSize: 2,
      fontWeight: 'bold'
    },
    title: {
      marginBottom: 1
    },
    subtitle: {
      marginBottom: 1
    },
    text: {
      marginBottom: 1
    }
  }
}
