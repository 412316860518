export default {
  pageHeroV1a: {
    container: {
      height: ['100vh', '', '100vh', '100vh'],
      width: '100%',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
      zIndex: '1',
      overflow: 'hidden'
    },
    content: {
      flexDirection: 'column',
      color: 'white',
      textAlign: 'center',
      h1: {
        marginBottom: 1,
        transformOrigin: 'bottom left',
        transform: 'translateX(100%) rotate(270deg)',
        // top: '50%',
        bottom: ['12.5vh', '', '15vh'],
        right: '3rem',
        position: 'absolute',
        fontWeight: '300',
        fontSize: ['3rem', '', '4rem'],
        width: ['60vh', '', '', '70vh'],
        textTransform: 'uppercase',
        textAlign: 'left'
      },
      button: {
        variant: 'buttons.multiLocation'
      }
    }
  }
}
