export default {
  noDataContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: '1',
    width: '100%',
    minHeight: '300px',
    color: 'text',
    textAlign: 'center'
  },

  noDataLogo: {
    maxWidth: '200px',
    marginBottom: '2rem'
  },
  NoDataText1: {
    marginBottom: '0.5rem'
  },
  NoDataText2: {
    opacity: '0.8'
  }
}
