const variantName = 'socialIconsV1'

export default {
  [variantName]: {
    container: {
      height: '25px',
      a: {
        height: 'inherit',
        width: 'inherit'
      },
      svg: {
        marginRight: 2,
        height: 'inherit',
        width: 'inherit'
      },
      path: {
        fill: 'inherit'
      }
    }
  }
}
