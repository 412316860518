const variantName = 'contentSectionV1b'

export default {
  [variantName]: {
    // ?============================
    // ?======  Containers =========
    // ?============================

    container: {
      position: 'relative',
      whiteSpace: 'break-spaces',
      color: 'light',
      paddingX: ['1rem'],
      paddingY: ['3rem', '', '12rem'],
      backgroundColor: 'background',
      backgroundAttachment: ['', '', '', 'fixed'],
      backgroundPosition: '50%',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat'
    },
    section: {
      width: '95%',
      maxWidth: '1200px',
      margin: '0 auto',
      zIndex: '100',
      color: 'white',
      display: 'grid !important',
      gridTemplateColumns: 'repeat(5, 1fr)',
      gridTemplateRows: 'repeat(4, 1fr)',
      gridColumnGap: '30px',
      gridRowGap: '0px',
      gridColumnStart: '1',
      gridColumnEnd: '5',
      gridRowStart: '1',
      gridRowEnd: '4',
      '.linksContainer': {
        gridColumnStart: 3,
        gridColumnEnd: 5,
        gridRowEnd: 4,
        gridRowStart: 3,
        mt: '2rem'
      },
      '.text': {
        gridColumnStart: 3,
        gridColumnEnd: 8,
        gridRowEnd: 3,
        gridRowStart: 1
      }
    },

    // ?============================
    // ?======  Text Content =======
    // ?============================

    title: {
      variant: 'text.title',
      marginBottom: 3,
      gridColumnStart: 1,
      gridColumnEnd: 3,
      gridRowStart: 1,
      gridRowEnd: 2
    },
    subtitle: {
      variant: 'text.subtitle',
      m: '0rem',
      marginBottom: 3,
      gridColumnStart: 1,
      gridColumnEnd: 3,
      gridRowStart: 2,
      gridRowEnd: 3
    },

    // ?============================
    // ?========  Buttons  =========
    // ?============================

    ctaButton: {
      variant: 'buttons.primary'
    },
    secondaryCtaButton: {
      variant: 'buttons.secondary'
    }
  }
}
