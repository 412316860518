const variantName = 'eventsV2'

export default {
  [variantName]: {
    // ?============================
    // ?======  Containers =========
    // ?============================
    container: {
      padding: '1rem'
    },

    // ?============================
    // ?======  Text Content =======
    // ?============================

    heading: {
      padding: '0.5rem;',
      textAlign: 'center',
      fontWeight: 'bold',
      fontSize: '2rem',
      textTransform: 'uppercase'
    },

    textContent: {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '2rem 1rem 1rem'
    },
    title: {
      fontSize: ['2rem'],
      marginBottom: '1rem'
    },
    subtitle: {
      marginBottom: '1rem'
    },
    text: {},

    // ?============================
    // ?===== Events Container =====
    // ?============================

    eventsContainer: {
      justifyContent: 'center',
      flexWrap: 'wrap',
      margin: '0 auto',
      maxWidth: '1000px'
    },

    // ?============================
    // ?======  Event Item  ========
    // ?============================
    eventItemContainer: {
      margin: ['2%'],
      width: ['100%'],
      flexDirection: ['column', 'row'],
      border: 'solid 1px lightgrey'
    },
    eventItemImage: {
      width: ['100%', '50%'],
      maxHeight: '300px',

      objectFit: 'cover'
    },
    eventItemContent: {
      width: ['100%', '50%'],
      flexGrow: '1',
      padding: 4,
      flexDirection: ['column']
    },
    eventItemTitle: {
      fontSize: 5,
      textTransform: 'capitalize',
      textAlign: 'left',
      fontWeight: 'bolder',
      fontFamily: 'heading',
      marginBottom: 4
    },

    eventTime: {
      marginBottom: 4
    },

    eventItemBtn: {
      variant: 'buttons.primary',
      padding: '0px',
      margin: '0px',
      a: {
        padding: '0.75rem 1.5rem',
        display: 'flex',
        width: '100%',
        height: '100%',
        justifyContent: 'center'
      }
    },

    eventItemDateContainer: {
      textAlign: ['center'],
      margin: '0px 0px 1rem',
      padding: '1 1 1 0',
      display: 'inline-block',
      alignItems: 'center',
      width: 'fit-content'
    },

    eventDateContainer: {},
    eventDate: {
      fontSize: 6,
      color: 'primary',
      fontWeight: 'bolder',
      width: '100%',
      marginBottom: 0,
      fontFamily: 'body'
    },
    eventDaysContainer: {
      textAlign: 'left'
    },
    eventDays: {
      textTransform: 'capitalize',
      color: 'primary',
      fontWeight: 'bold'
    },
    eventMonth: {
      textTransform: 'uppercase',
      marginBottom: 0,
      width: '100%',
      fontSize: 3,
      fontFamily: 'body'
    },
    eventItemDescription: {
      flexGrow: '1'
    },

    // ?============================
    // ?======  Modal Styles =======
    // ?============================

    modalContainer: {
      position: 'fixed',
      top: '0px',
      bottom: '0px',
      left: '0px',
      right: '0px'
    },
    modalBackground: {
      position: 'absolute',
      top: '0px',
      bottom: '0px',
      left: '0px',
      right: '0px',
      backgroundColor: 'rgba(0,0,0,0.5)'
    },
    modal: {
      padding: '0px',
      maxWidth: '767px',
      zIndex: '9999',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)',
      backgroundColor: 'light',
      minWidth: '280px',
      borderRadius: '10px',
      overflow: 'hidden',
      boxShadow: '2px 2px 8px dark'
    },
    modalImage: {
      maxHeight: ['175px', '250px', '500px'],
      width: '100%',
      objectFit: 'cover'
    },

    modalContentContainer: {
      padding: 3,
      color: 'dark'
    },

    modalEventName: {
      fontFamily: 'heading',
      fontWeight: 'heading',
      lineHeight: 'heading',
      textTransform: 'capitalize',
      color: 'text',
      fontSize: 3,
      marginBottom: 3,
      textAlign: 'left'
    },
    modalEventDate: {
      alignItems: 'center',
      marginBottom: 3,
      color: 'dark'
    },
    modalEventTime: {
      marginBottom: '1rem'
    },

    datesSeperator: {
      color: 'dark',
      marginRight: 2,
      marginLeft: 1
    },
    modalEventDescription: {},

    eventCTAS: { flexWrap: 'wrap', marginBottom: '0.5rem' },
    eventCTA: {
      margin: '0.5rem 1rem 0.5rem 0rem',
      textDecoration: 'underline',
      fontSize: ['1.25rem', '1.3rem'],
      cursor: 'pointer',
      // ?== Psuedo Elements ==
      ':hover': {
        color: 'primary'
      }
    }
  }
}
