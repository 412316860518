export default {
  sideBySideV2a: {
    container: {
      backgroundColor: 'background',
      flexDirection: ['column', 'column', 'column', 'row'],
      alignItems: ['', '', '', 'stretch'],
      padding: '2rem 0'
    },
    content: {
      flexDirection: 'column',
      padding: [4, '', '', 8],
      width: ['100%', '100%', '100%', '40%'],
      justifyContent: 'center',
      textAlign: 'center',
      alignItems: 'center',
      a: {
        display: 'flex',
        width: 'fit-content'
      }
    },
    subtitle: {
      fontWeight: 6,
      marginBottom: 3
    },
    title: {
      fontSize: 6,
      marginBottom: 3
    },

    ctaButton: {
      padding: 3,
      marginTop: 5,
      border: 'solid 2px',
      borderColor: 'primary',
      backgroundColor: 'primary',
      width: 'max-content',
      textTransform: 'uppercase',
      fontWeight: 'bold',
      color: 'light',
      '&:hover': {
        backgroundColor: 'secondary'
      }
    },

    text: {
      lineHeight: '1.75',
      whiteSpace: 'break-spaces'
    },
    image: {
      width: ['100%', '100%', '100%', '50%'],
      margin: ['0.5rem', '', 'auto 0.5rem'],
      marginLeft: ['0.5rem', '', '1rem', ''],
      objectFit: 'cover',
      maxHeight: '60vw'
    },
    imageContainer: {
      width: ['100%', '100%', '100%', '60%'],
      '.lazyload-wrapper': {
        display: 'flex',
        width: '100%'
      },
      '.image1': {
        height: '100%'
      },
      '.image2': {
        height: ['100%', '', '', '75%']
      }
    }
  }
}
