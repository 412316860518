const variantName = 'navigationV1'

export default {
  [variantName]: {
    container: {
      position: 'fixed',
      width: '100%',
      top: '0px',
      left: '0px',
      zIndex: '1000',
      boxSizing: 'border-box',
      height: '75px',
      padding: '0.5rem',
      display: 'flex',
      transition: 'all ease-in-out 0.5s',
      alignItems: 'center',
      justifyContent: 'flex-end'
    },

    hamburger: {
      position: 'relative',
      display: 'flex',
      zIndex: '2000',
      alignSelf: 'center',
      flexDirection: 'column',
      justifyContent: 'space-between',
      width: '35px',
      height: '25px',
      cursor: 'pointer',
      margin: '0 0.5rem 0 auto',
      div: {
        alignSelf: 'flex-end',
        transition: 'all ease-in-out 0.3s',
        height: '4px',
        width: '100%',
        background: 'black',
        boxShadow: '2px 2px 1px white'
      }
    },
    hamburgerOpen: {
      position: 'relative',
      display: 'flex',
      zIndex: '2000',
      alignSelf: 'center',
      flexDirection: 'column',
      justifyContent: 'center',
      width: '35px',
      height: '25px',
      cursor: 'pointer',
      margin: '0 0.5rem 0 auto',
      div: {
        position: 'absolute',
        transition: 'all ease-in-out 0.3s',
        height: '4px',
        width: '100%',
        background: 'black',
        boxShadow: 'none'
      },
      '& > :nth-child(1)': {
        transform: 'rotate(135deg) '
      },
      '& > :nth-child(2)': {
        transform: 'rotate(-135deg)'
      }
    },

    navLogo: {
      maxWidth: '200px',
      margin: '0.5rem 0rem 0rem 0rem'
    },

    logo: {
      position: ['absolute', '', '', ''],
      top: ['0', '', '', '0px'],
      left: ['50%', '', '', '4'],
      transform: ['translateX(-50%)', '', '', 'unset'],
      alignSelf: 'top',
      zIndex: '2000',
      transition: 'all ease-in-out 0.3s',
      img: {
        maxHeight: '90px'
      }
    },

    logoScrolled: {
      variant: `${variantName}.logo`,
      maxHeight: ['100%', '100px'],
      textAlign: ['center', '', '', 'left'],
      left: ['50%', '', '', '4'],
      top: ['0px', '', '', '0px'],
      transform: ['translateX(-50%)', '', '', 'unset'],
      height: ['100%'],
      display: ['', '', '', 'inline'],
      img: {
        height: ['100%'],
        padding: 0
      }
    },

    navMenu: {
      position: 'absolute',
      margin: '0px',
      boxSizing: 'border-box',
      justifyContent: 'space-between',
      height: '100vh',
      backgroundColor: 'primary',
      top: '0px',
      zIndex: '1000',
      width: '50%',
      minWidth: '250px',
      left: -'120%',
      transition: 'all ease-in-out 0.5s',
      padding: '0.5rem 0.5rem 3rem',
      alignItems: 'flex-start',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
      listStyleType: 'none',
      paddingTop: ['180px', '', 'unset']
    },
    navItem: {
      marginBottom: '0.75rem',
      zIndex: '100',
      marginLeft: '1rem',
      listStyleType: 'none',
      a: {
        fontSize: '1.25em',
        textTransform: 'uppercase',
        fontWeight: '400',
        color: 'light',
        letterSpacing: '2px',
        textDecoration: 'none'
      }
    },

    socialIconsContainer: {
      marginTop: '1rem',
      marginLeft: '1rem',
      svg: {
        width: '20px',
        height: '20px',
        marginRight: '0.5rem'
      }
    },
    navBlock: {
      display: 'flex',
      backgroundColor: 'rgba(255, 255, 255, 0.7)',
      position: 'absolute',
      margin: '0rem',
      zIndex: '500',
      transition: 'all ease-in-out 0.5s',
      top: '0rem',
      left: '-200%',
      width: '100%',
      height: '100vh'
    }
  }
}
