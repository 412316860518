export default {
  sliderV2: {
    container: {
      paddingY: '3rem',
      svg: {
        color: 'text'
      },

      // slick styles
      '.slick-initialized .slick-slide > div': {
        overflow: 'hidden',
        position: 'relative'
        // height: '30vh',
      },
      '.slick-slide img': {
        height: '100%',
        position: 'absolute',
        top: '50%',
        left: '50%',
        margin: '0rem',
        objectFit: 'cover',
        width: '100%',
        transform: 'translate(-50%, -50%)'
      },
      '.slick-dots': {
        bottom: '-5px'
      },
      '.slick-prev:before, .slick-next:before': {
        color: 'white',
        fontSize: '25px'
      },

      '.slick-dots li button:before': {
        color: 'white',
        fontSize: '10px'
      },
      '.slick-next, .slick-prev': {
        zIndex: '500',
        width: '30px',
        height: '30px',
        fontSize: '1rem',
        border: 'solid 1px',
        color: 'primary',
        borderColor: 'primary',
        backgroundColor: 'rgba(255,255,255,0.4)'
      },

      '.slick-next': {
        right: '5px'
      },
      '.slick-prev': {
        left: '0px'
      },
      '.slick-prev:hover, .slick-prev:focus, .slick-next:hover, .slick-next:focus': {
        opacity: '0.7',
        color: 'inherit'
      }
    },
    content: {
      padding: ['1rem', '2rem', '3rem', '4rem'],
      flexDirection: 'column',
      '.linksContainer': {
        mt: '2rem'
      },
      maxWidth: '1100px'
    },
    slideBox: {
      flexDirection: 'column',
      display: 'flex !important', // slider package gives it a inline style of inline block
      margin: '0.5rem',
      width: 'calc(100% - 1rem)!important'
    },
    imageContainer: {
      position: 'relative'
    },
    imageFill: {
      paddingBottom: '100%'
    },
    title: { variant: 'text.title' },
    subtitle: { variant: 'text.subtitle' },
    text: { variant: 'text.text' },

    ctaButton: {
      variant: 'buttons.primary'
    },
    secondaryCtaButton: {
      variant: 'buttons.secondary'
    }
  }
}
