export default {
  sectionNavLinksV2f: {
    container: {
      // backgroundColor: 'backgroundSecondary',
      padding: ['', '', '1rem'],
      '.lazyload-wrapper': {
        width: ['100%', '100%', 'calc(50% - 3rem)', 'calc(33.33% - 3rem)'],
        margin: '0.5rem'
      }
    },
    boxes: {
      margin: '0 auto',
      flexWrap: 'wrap',
      justifyContent: 'center',
      width: '100%',
      display: 'flex'
    },
    box: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      height: '300px',
      alignItems: 'stretch',
      flexGrow: '1',
      backgroundPosition: 'center',
      transition: 'all ease-in-out 2s',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      position: 'relative',
      '::before': {
        content: "''",
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: '0rem',
        left: '0rem',
        right: '0rem',
        bottom: '0rem',
        background:
          'linear-gradient(180deg, rgba(56,56,56,0.2763480392156863) 0%, rgba(25,25,25,0.7973564425770308) 100%)'
      },
      '&:hover': {
        backgroundPosition: 'bottom right',
        backgroundSize: 'cover'
      },
      a: {
        width: '100%',
        padding: '1rem 2rem',
        textDecoration: 'none',
        display: 'flex',
        position: 'relative',
        alignItems: 'center',
        justifyContent: 'center',
        '&:hover > *': {
          backgroundColor: 'primary'
        }
      }
    },
    linkName: {
      width: '100%',
      color: 'light',
      backgroundColor: 'rgb(255 255 255 / 21%)',
      border: 'solid 2px white',
      padding: 3,
      fontSize: 4,
      maxWidth: '200px',
      margin: '0 auto',
      textTransform: 'uppercase',
      transition: 'all ease-in-out 0.5s',
      textAlign: 'center'
    }
  }
}
