const variantName = 'hoursV1a'

export default {
  [variantName]: {
    // ?============================
    // ?======  Containers =========
    // ?============================
    container: {},
    section: {
      width: '100%',
      padding: '2rem 1rem',
      maxWidth: ['400px', '', '', ''],
      margin: '0 auto'
    },

    // ?============================
    // ?======  Text Content =======
    // ?============================

    textContent: {},

    title: {
      textAlign: 'center',
      fontSize: '2rem',
      marginBottom: '1rem'
    },
    subtitle: {
      fontSize: '1.5rem',
      marginBottom: '1rem',
      textAlign: 'center'
    },

    text: {
      fontSize: '1.5rem',
      marginBottom: '1rem',
      textAlign: 'center'
    },

    // ?============================
    // ?=====  Hours Container =====
    // ?============================

    hoursContainer: {
      flexDirection: ['column', '', '', '']
    },

    // ?============================
    // ?======  Day Container ======
    // ?============================

    dayContainer: {
      flexWrap: 'wrap',
      marginBottom: 3,
      width: '100%'
    },

    dayofWeekText: {
      textTransform: 'capitalize',
      fontWeight: 'bold'
    },

    // ?============================
    // ?=======  Time Blocks =======
    // ?============================

    timeBlocks: {
      flexDirection: ['column']
    },
    timeBlock: {
      width: '100%'
    },

    label: {
      textTransform: 'capitalize'
    },

    time: {
      flewGrow: '1',
      margin: ['0 0 0 auto', '', '', '']
    },
    closed: {
      textAlign: 'center',
      margin: ['0 0 0 auto', '', '', '']
    }
  }
}
