const variantName = 'heroV9'

export default {
  [variantName]: {
    // ?============================
    // ?======  Containers =========
    // ?============================

    container: {},
    hero: {
      borderColor: 'primary'
    },

    // ?============================
    // ?========  Images  ==========
    // ?============================

    image: {
      boxShadow: '3px 3px 12px black',
      border: '2px solid',
      borderColor: 'orange'
    },

    // ?============================
    // ?=======  Hero Shout  =======
    // ?============================

    shoutWidget: {
      backgroundColor: 'orange',
      padding: '3rem'
    }
  }
}
