const variantName = 'contentBoxesV4'

export default {
  [variantName]: {
    // ?============================
    // ?======  Containers =========
    // ?============================
    container: {
      padding: '3rem 1rem'
    },

    // ?============================
    // ?======  Containers =========
    // ?============================

    boxTabs: {
      display: 'flex',
      flexDirection: ['row', '', 'row'],
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      p: ['0.5rem', '', '2rem'],
      mt: '2.5%',
      width: '100%'
    },
    boxTab: {
      variant: 'buttons.primary',
      padding: ['0.5rem', '', '1.5rem 2.5rem'],
      fontSize: ['0.8rem', '', '1rem'],
      m: ['0.25rem', '', '0.5rem'],
      width: ['fit-content', '', 'fit-content'],
      minWidth: ['', '', 'calc(25% + 1rem)'],
      cursor: 'pointer',
      flexGrow: '1'
    },
    boxTabActive: {
      variant: 'buttons.primary',
      padding: ['0.5rem', '', '1.5rem 2.5rem'],
      fontSize: ['0.8rem', '', '1rem'],
      m: ['0.25rem', '', '0.5rem'],
      width: ['fit-content', '', 'fit-content'],
      flexGrow: '1',
      minWidth: ['', '', 'calc(25% + 1rem)'],
      opacity: '0.8'
    },

    // ?============================
    // ?=========  Boxes  ==========
    // ?============================
    container: {
      flexWrap: 'wrap',
      justifyContent: 'center',
      margin: '0 auto'
    },
    boxes: {},
    box: {
      display: 'flex',
      flexDirection: ['column', '', 'row'],
      width: '100%',
      padding: '5%'
    },

    // ?============================
    // ?========  Images  ==========
    // ?============================

    imageContainer: {
      width: ['100%', '', '50%']
    },

    image: {
      marginBottom: 1,
      width: '100%',
      height: '100%',
      objectFit: 'cover'
    },

    // ?============================
    // ?======  Text Content =======
    // ?============================

    content: {
      width: ['100%', '', '50%'],
      padding: '5%',
      '.linksContainer': {
        mt: '2rem'
      }
    },

    date: {
      marginBottom: 1,
      fontSize: 2,
      paddingX: 2,
      fontWeight: 'bold'
    },
    title: {
      variant: 'text.title',
      marginBottom: 4
    },
    subtitle: {
      variant: 'text.subtitle',
      marginBottom: 4
    },
    text: {
      variant: 'text.text',
      marginBottom: 8
    },

    // ?============================
    // ?========  Buttons  =========
    // ?============================

    ctaButton: {
      variant: 'buttons.primary'
    },
    secondaryCtaButton: {
      variant: 'buttons.secondary'
    }
  }
}
