const variantName = 'eventsV4'

export default {
  [variantName]: {
    // ?============================
    // ?======  Containers =========
    // ?============================
    container: {
      padding: '1rem'
    },

    // ?============================
    // ?======  Text Content =======
    // ?============================

    heading: {
      padding: '0.5rem;',
      textAlign: 'center',
      fontWeight: 'bold',
      fontSize: '2rem',
      textTransform: 'uppercase'
    },

    textContent: {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '2rem 1rem 1rem'
    },
    title: {
      fontSize: ['2rem'],
      marginBottom: '1rem'
    },
    subtitle: {
      marginBottom: '1rem'
    },
    text: {},

    // ?============================
    // ?===== Events Container =====
    // ?============================

    eventsContainer: {
      justifyContent: 'center',
      flexWrap: 'wrap'
    },

    // ?============================
    // ?======  Event Item  ========
    // ?============================

    eventItemContainer: {
      margin: ['1rem 0.5rem', '1rem'],
      width: ['calc(100% - 1rem)', 'calc(50% - 2rem)', 'calc(33.3% - 2rem)'],
      flexDirection: 'column'
    },

    eventItemImage: {
      minHeight: '250px',
      maxHeight: '250px',
      objectFit: 'cover'
    },

    eventItemContent: {
      flexGrow: '1',
      paddingTop: 2,
      paddingBottom: 2,
      flexDirection: ['column', 'row']
    },

    eventItemTitle: {
      fontSize: 4,
      textTransform: 'capitalize',
      textAlign: 'left',
      fontWeight: 'bolder',
      fontFamily: 'heading'
    },

    eventItemBtn: {
      variant: 'buttons.secondary'
    },

    eventItemDateContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: ['left', 'center'],
      maxWidth: '50px',
      maxHeight: '50px',
      minWidth: 'unset',
      marginRight: [0, 1]
    },

    eventDate: {
      fontSize: 3,
      color: 'secondary',
      fontWeight: 'bolder',
      width: '100%',
      marginBottom: 0,
      fontFamily: 'body'
    },

    eventDaysContainer: {
      marginTop: '0.5rem'
    },

    eventDays: {
      textTransform: 'capitalize',
      fontWeight: 'bold'
    },

    eventMonth: {
      textTransform: 'uppercase',
      marginBottom: 0,
      width: '100%',
      fontFamily: 'body'
    },

    // ?============================
    // ?======  Modal Styles =======
    // ?============================

    modalContainer: {
      position: 'fixed',
      top: '0px',
      bottom: '0px',
      left: '0px',
      right: '0px',
      zIndex: '9999'
    },

    modalBackground: {
      position: 'absolute',
      top: '0px',
      bottom: '0px',
      left: '0px',
      right: '0px',
      backgroundColor: 'rgba(0,0,0,0.5)'
    },

    modal: {
      padding: 0,
      maxWidth: '1000px',
      zIndex: '9999',
      width: '95%',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)',
      color: 'text',
      backgroundColor: 'background',
      minWidth: '280px',
      border: 'solid 6px white'
    },

    modalImage: {
      maxHeight: ['175px', '250px', '500px'],
      width: '100%',
      objectFit: 'cover'
    },

    modalContentContainer: {
      padding: 3,
      color: 'text'
    },

    modalEventName: {
      fontFamily: 'heading',
      fontWeight: 'heading',
      lineHeight: 'heading',
      textTransform: 'capitalize',
      fontSize: 5,
      marginBottom: 3,
      color: 'text',
      textAlign: 'left',
      display: 'flex'
    },

    modalEventDate: {
      alignItems: 'center',
      marginBottom: 3,
      color: 'text'
    },

    modalEventTime: {
      marginBottom: '1rem',
      display: 'flex'
    },

    datesSeperator: {
      color: 'text',
      marginRight: 2,
      marginLeft: 1
    },
    modalEventDescription: {
      color: 'text'
    },

    eventCTAS: { flexWrap: 'wrap', marginBottom: '0.5rem' },
    eventCTA: {
      variant: 'buttons.primary',
      margin: '0.5rem 1rem 0.5rem 0rem',
      textDecoration: 'underline',
      fontSize: ['1.25rem', '1.5rem'],
      cursor: 'pointer',
      // ?== Psuedo Elements ==
      ':hover': {
        color: 'primary'
      }
    }
  }
}
