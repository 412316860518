import noDataContainer from '../Reuseables/NoDataContainer'
const variantName = 'policiesMenu'

export default {
  [variantName]: {
    // ?============================
    // ?======  Containers =========
    // ?============================

    container: {
      padding: ['9rem 0.5rem 0.5rem', '10rem 1rem 1rem'],
      display: ['', '', '', 'flex']
    },

    // ?============================
    // ?========  Filters   ========
    // ?============================

    filters: {
      display: 'flex',
      padding: ['0rem', '', '', '0.5rem'],
      flexDirection: ['', '', '', 'column'],
      minWidth: '250px',
      borderRight: ['', '', '', '1px solid'],
      marginRight: ['', '', '', '1rem'],
      flexWrap: 'wrap'
    },
    filtersTitle: {
      width: '100%',
      padding: '0.5rem',
      marginBottom: '0.5rem'
    },

    filterLabel: {
      marginBottom: '0.25rem',
      padding: '0.25rem',
      fontSize: '0.8rem'
    },

    orderByContainer: {
      minWidth: '100px',
      margin: '0.5rem'
    },
    searchContainer: {
      flexGrow: ['1', '', '', '0'],
      margin: ['0.5rem'],
      svg: {
        position: 'absolute',
        right: '0.75rem',
        top: '50%',
        transform: 'translateY(-50%)'
      }
    },
    searchInputContainer: {
      position: 'relative'
    },

    sortByContainer: {
      margin: '0.5rem'
    },

    // ?============================
    // ?======  Article Boxes ======
    // ?============================
    blogsContainer: {
      display: 'flex',
      width: '100%',
      flexDirection: 'column',
      justifyContent: 'center'
    },
    boxesHeading: {
      textTransform: 'uppercase',
      textAlign: 'center',
      borderBottom: '2px solid',
      width: 'fit-content',
      padingBottom: '0.5rem',
      margin: ['2rem auto 2rem', '', '', '2rem auto 2rem']
    },

    boxesContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'flex-start',
      alignItems: 'stretch',
      width: '100%',
      padding: '0.5rem'
    },

    box: {
      width: ['100%', 'calc(50% - 1rem)', 'calc(33.3% - 2rem)', '', 'calc(25% - 2rem)'],
      border: 'solid 1px black',
      margin: ['1rem 0rem', '0.5rem', '1rem'],
      a: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      },
      ':hover': {
        '.textContent': {
          opacity: '0.8'
        },
        img: {
          transform: 'translate(-50%, -50%) scale(1.1)'
        }
      }
    },

    // ?============================
    // ?======  Image Content =======
    // ?============================

    imageContainer: {
      width: '100%',
      position: 'relative',
      overflow: 'hidden'
    },
    imageFill: {
      paddingBottom: '100%'
    },
    image: {
      transition: 'all ease-in-out 0.5s',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)',
      width: '100%',
      height: '100%',
      objectFit: 'cover'
    },

    // ?============================
    // ?======  Text Content =======
    // ?============================
    textContent: {
      padding: '1rem',
      fontSize: '1rem',
      borderTop: '1px solid',
      transition: 'all ease-in-out 0.5s',
      backgroundColor: 'background',
      flexGrow: '1',
      display: 'flex',
      flexDirection: 'column'
    },
    // name: {
    //   fontSize:'1.5em',
    //   marginBottom: '1rem',
    // },
    title: {
      fontSize: '1.5em',
      marginBottom: '1rem',
      color: 'primary'
    },
    subtitle: {
      fontSize: '1.25em',
      marginBottom: '1rem',
      opacity: '0.7'
    },
    text: {
      fontSize: '0.9em',
      marginBottom: '1rem',
      flexGrow: '1'
    },

    timeContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      borderTop: '1px solid',
      opacity: '0.6',
      padding: '0.5rem 0rem 0rem',
      margin: 'auto 0rem 0rem'
    },

    readingTime: {
      fontSize: '0.8em',
      margin: '0rem 1rem 0rem 0rem',
      padding: '0.5rem 0rem'
    },

    createdAt: {
      fontSize: '0.8em',
      margin: '0rem 1rem 0rem 0rem',
      padding: '0.5rem 0rem'
    },

    // ?============================
    // ?========  Buttons  =========
    // ?============================

    ctaContainer: {
      a: {
        display: 'flex',
        variant: 'buttons.primary'
      }
    },

    // ?=============================
    // ?====  No Data Container  ====
    // ?=============================

    ...noDataContainer
  }
}
