const variantName = 'navigationV3'

export default {
  [variantName]: {
    // ?============================
    // ?======== Container =========
    // ?============================

    container: {
      position: 'fixed',
      width: '100%',
      top: '0px',
      left: '0px',
      zIndex: '1000',
      boxSizing: 'border-box',
      height: ['75px', '75px', '75px', 'unset'],
      padding: ['0.5rem', '', '0px'],
      display: 'flex',
      transition: 'all ease-in-out 0.5s',
      alignItems: 'center',
      justifyContent: 'flex-end',
      background: 'linear-gradient(180deg, rgba(0,0,0,0.75) 0%, rgba(0,0,0,0.33) 50%, rgba(255,255,255,0) 100%);'
    },

    containerScrolled: {
      variant: `${variantName}.container`
    },

    // ?============================
    // ?=======  Nav Logos =========
    // ?============================

    logo: {
      position: ['absolute'],
      top: ['0', '', '', '0px'],
      left: ['50%', '', '', '4'],
      transform: ['translateX(-50%)', '', '', 'unset'],
      alignSelf: 'top',
      zIndex: '2000',
      img: {
        maxHeight: '100px',
        maxWidth: '200px'
      }
    },
    logoScrolled: {
      variant: `${variantName}.logo`,
      maxHeight: ['100%', '100px'],
      textAlign: ['center', '', '', 'left'],
      left: ['50%', '', '', '4'],
      top: ['0px', '', '', '0px'],
      transform: ['translateX(-50%)', '', '', 'unset'],
      height: ['100%'],
      display: ['', '', '', 'inline'],
      img: {
        height: ['100%'],
        padding: 0
      }
    },

    // ?============================
    // ?=======  Hamburger =========
    // ?============================

    hamburger: {
      position: 'relative',
      display: ['flex', 'flex', 'flex', 'none'],
      zIndex: '2000',
      alignSelf: 'center',
      flexDirection: 'column',
      justifyContent: 'space-between',
      width: '35px',
      height: '25px',
      cursor: 'pointer',
      margin: '0 0.5rem 0 auto',
      div: {
        alignSelf: 'flex-end',
        transition: 'all ease-in-out 0.3s',
        height: '4px',
        width: '100%',
        backgroundColor: 'secondary',
        borderRadius: '10px'
      }
    },
    hamburgerOpen: {
      position: 'relative',
      display: ['flex', 'flex', 'flex', 'none'],
      zIndex: '2000',
      alignSelf: 'center',
      flexDirection: 'column',
      justifyContent: 'center',
      width: '35px',
      height: '25px',
      cursor: 'pointer',
      margin: '0 0.5rem 0 auto',
      div: {
        position: 'absolute',
        transition: 'all ease-in-out 0.3s',
        height: '4px',
        width: '100%',
        backgroundColor: 'text',
        boxShadow: 'none',
        borderRadius: '10px'
      },
      '& > :nth-child(1)': {
        transform: 'rotate(135deg) '
      },
      '& > :nth-child(2)': {
        transform: 'rotate(-135deg)'
      }
    },

    // ?============================
    // ?========  Nav Menu =========
    // ?============================

    navMenu: {
      position: ['absolute', 'absolute', 'absolute', 'unset'],
      margin: '0px',
      boxSizing: 'border-box',
      justifyContent: ['center', '', '', 'flex-end'],
      height: ['100vh', '100vh', '100vh', 'unset'],
      backgroundColor: ['primary', 'primary', 'primary', 'unset'],
      top: '0px',
      zIndex: '1000',
      width: ['100%', '100%', '100%', '100%'],
      minWidth: '250px',
      left: -'120%',
      transition: 'all ease-in-out 0.5s',
      padding: ['2rem', '', '', '0.5rem'],
      alignItems: ['flex-start', '', '', 'center'],
      display: 'flex',
      flexDirection: ['column', 'column', 'column', 'row'],
      // overflow: 'hidden',
      listStyleType: 'none'
    },
    navItem: {
      marginBottom: ['0.75rem', '', '', '0px'],
      zIndex: '100',
      marginLeft: ['0px', '', '0px'],
      margin: ['', '', '', '0.75rem'],
      listStyleType: 'none',
      a: {
        fontSize: '1rem',
        textTransform: 'uppercase',
        fontWeight: '400',
        letterSpacing: '2px',
        textDecoration: 'none'
      }
    },

    // ?==========================
    // ?====  Dropdown Styles ====
    // ?==========================

    navItemDropdown: {
      alignItems: 'inherit',
      fontFamily: 'inherit',
      justifyContent: 'inherit',
      position: 'relative'
    },

    subNavItemDropdown: {
      variant: `${variantName}.navItem`,
      padding: '0.5rem',
      margin: ['0rem', '0rem', '0rem', '0rem'],
      whiteSpace: 'nowrap'
    },

    navItemDropdownMenu: {
      padding: '0.5rem',
      textShadow: '0px 0px 30px rgb(122 122 122 / 79%)',
      position: ['', '', '', 'absolute'],
      display: 'flex',
      alignItems: 'inherit',
      justifyContent: 'inherit',
      flexDirection: 'column',
      width: 'auto',
      backgroundColor: 'primary',
      '.navItem': {
        ':hover': {
          color: 'light',
          opacity: '0.8'
        }
      }
    },

    // ?============================
    // ?====  Extra Nav Widgets ====
    // ?============================

    socialIconsContainer: {
      margin: ['1rem 0rem', '', '', '0rem 1.5rem'],
      svg: {
        marginRight: '0.5rem',
        width: '25px',
        height: '25px'
      }
    },
    phoneContainer: {
      margin: ['1rem 0rem 0rem', '', '', '0rem 1.5rem']
    },

    locationSwitcher: {
      backgroundColor: '',
      position: ['fixed', '', '', 'unset'],
      left: ['0'],
      top: ['35px'],
      transform: ['translateY(-50%)', '', '', 'unset'],
      zIndex: '2000'
    }
  }
}
