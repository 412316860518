export default {
  titleBlockV2: {
    container: {
      padding: ['3rem 1.5rem 1rem', '', '3rem 3rem 1rem']
    },
    gradientText: {
      variant: 'text.title',
      fontSize: ['2.2rem', '2.5rem', '3rem', '4rem'],
      mb: '1rem'
    },
    subtitle: {}
  }
}
