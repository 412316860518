export default {
  sectionNavLinksV2c: {
    container: {
      padding: ['', '', '4rem 1rem'],
      flexDirection: 'column',
      '.lazyload-wrapper': {
        width: '100%'
      }
    },
    boxes: {
      maxWidth: '1200px',
      margin: '0 auto',
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      display: ['grid'],
      flexDirection: 'column',
      gridTemplateRows: ['1fr', '', '1fr 1fr'],
      gridTemplateColumns: ['1fr', '', '1fr 1fr 1fr'],
      gridGap: '1.5rem',
      width: '100%',
      padding: '1.5rem'
    },
    box: {
      '&:nth-child(1)': {
        gridRowStart: ['', '', '1'],
        gridRowEnd: ['', '', '3']
      },
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      minHeight: '200px',
      height: '100%',
      alignItems: 'stretch',
      flexGrow: '1',
      backgroundPosition: 'center',
      transition: 'all ease-in-out 2s',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      '&:hover': {
        backgroundPosition: 'bottom left'
      },
      a: {
        width: '100%',
        textDecoration: 'none',
        display: 'flex',
        position: 'relative',
        alignItems: 'center',
        justifyContent: 'center'
      }
    },
    linkName: {
      color: 'light',
      padding: 4,
      fontSize: 4,
      display: 'inline-block',
      margin: '0 auto',
      textTransform: 'uppercase',
      transition: 'all ease-in-out 0.5s',
      textAlign: 'right',
      bottom: 1,
      right: 1,
      position: 'absolute'
    }
  }
}
