const variantName = 'multiButtonsV1'

export default {
  [variantName]: {
    // ?============================
    // ?======  Containers =========
    // ?============================
    container: {
      flexWrap: 'wrap',
      margin: 3,
      justifyContent: 'center',

      // TODO: change a to a variantName and give it is own variant rather than nested
      a: {
        display: 'flex',
        alignItems: 'center',
        padding: '0.5rem 1rem',
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: 'inherit',
        color: 'inherit',
        textDecoration: 'none',
        margin: 2,
        textTransform: 'uppercase',
        transition: 'all ease-in-out 0.5s',
        // ?== Psuedo Elements ==
        '&:hover': {
          backgroundColor: 'primary',
          borderColor: 'primary'
        }
      }
    }
  }
}
