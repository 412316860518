export default {
  titleBlockV1: {
    container: {
      paddingX: 3,
      paddingY: 8,
      textAlign: 'center',
      '& :last-child': {
        marginBottom: '0px'
      }
    },
    title: { fontSize: '2rem' },
    subtitle: { marginBottom: '1rem' }
  }
}
