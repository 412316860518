const variantName = 'footerV5'

export default {
  [variantName]: {
    // ?============================
    // ?======  Containers =========
    // ?============================
    container: {
      paddingX: [4, '', '', 10],
      paddingY: 8,
      backgroundColor: 'primary',
      flexDirection: 'column'
    },
    row: {
      flexDirection: ['row', 'row', 'row', 'row'],
      flexWrap: ['wrap', 'wrap', 'wrap', 'nowrap'],
      alignItems: 'flex-start',
      '.column': {
        flexDirection: 'column',
        justifyContent: ['center', '', '', 'flex-start'],
        alignItems: ['center', '', '', 'flex-start'],
        width: ['100%', '100%', '100%', 'unset'],
        textAlign: 'left',
        padding: ['0rem', '', '', '2rem']
      }
    },

    // ?============================
    // ?======  Text Content =======
    // ?============================

    footerSectionHeading: {
      marginBottom: '1rem',
      fontSize: '1.25rem',
      display: ['none', '', '', 'block']
    },

    quote: {
      margin: '1rem 0rem',
      fontSize: '1rem',
      maxWidth: '300px',
      textAlign: ['center', '', '', 'left'],
      padding: '0rem'
    },

    // ?============================
    // ?=========  Logos  ==========
    // ?============================
    logo: {
      maxHeight: '150px'
    },

    columnLogo: {
      paddingRight: '2rem',
      margin: '0 auto 0 0'
    },

    // ?============================
    // ?========  Site Map =========
    // ?============================

    columnNav: {
      '.multiButtonContainer': {
        justifyContent: ['center', '', '', 'flex-start'],
        flexDirection: ['row', '', '', 'column'],
        margin: ['1rem 0.5rem', '', '', '0rem'],
        a: {
          margin: ['1rem', '', '', '0.5rem 0rem'],
          border: 'none',
          padding: '0rem'
        }
      }
    },

    // ?============================
    // ?========  Social  ==========
    // ?============================

    columnSocial: {
      '.socialContainer': {
        margin: ['1rem', '', '', '0rem'],
        flexDirection: ['row', '', '', 'column'],
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'center',
        a: {
          display: 'flex',
          svg: {
            marginRight: '0.5rem'
          },
          margin: ['1rem', '', '', '1rem 0rem']
        }
      }
    },

    // ?=============================
    // ?========  Contact  ==========
    // ?=============================

    columnContact: {
      '.contactDetails-container': {
        margin: ['1rem', '', '', '0rem'],
        '.text': {
          margin: ['1rem', '', '', '1rem 0rem']
        }
      }
    },

    // ?============================
    // ?======  Bottom Bar  ========
    // ?============================

    bottomBar: {
      width: '100%',
      flexDirection: ['column', '', '', 'column'],
      alignItems: ['center', '', '', 'flex-end'],
      justifyContent: ['center', '', '', 'flex-end'],
      // ?== Nested Elements ==
      '> div': {
        marginBottom: '1rem',
        margin: '0.5rem'
      }
    },

    // ?============================
    // ?========  Buttons  =========
    // ?============================

    ctasContainer: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'inherit',
      marginY: '1rem',
      // ?== Nested Elements ==
      '> div': {
        margin: '0rem'
      },
      button: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        flexDirection: 'row',
        margin: '0.5rem',
        backgroundColor: 'secondary',
        fontSize: '0.9rem',
        svg: {
          marginRight: '0.5rem'
        }
      }
    },

    popUpContentBoxesContainer: {
      margin: '0.5rem',
      // ?== Nested Elements ==
      '& > button': {},
      '.contentBoxes': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'stretch',
        flexDirection: ['column', '', 'row']
      },
      '.box': {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: '1',
        width: ['50%', '50%', '50', '25%']
      },
      '.image': {
        maxWidth: 'unset',
        width: '100%'
      }
    }
  }
}
