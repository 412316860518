import React from 'react'
import { ParallaxProvider } from 'react-scroll-parallax'
import { ContextProvider } from './src/Context/Store'

/**
 *
 * The Root of The App.
 *
 * We for any element that need to exist at the top level like a router. context,
 * theme provider. that wraps around the whole site.
 * this will wrap around everything single site in the generator so beware.
 *
 */

export const wrapRootElement = ({ element }) => (
  <ContextProvider>
    <ParallaxProvider>{element}</ParallaxProvider>
  </ContextProvider>
)
