export default {
  multiLocationsV3: {
    container: {},
    locationsContainer: {
      flexWrap: 'wrap',
      height: '100vh',
      width: '100vw',
      overflow: 'hidden'
    },
    location: {
      position: 'absolute',
      top: '0px',
      left: ['0px', '', '', 'unset'],
      right: ['', '', '', '0px'],
      height: '100vh',
      width: ['100vw', '', '', '50vw'],
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: ['center', '', '', 'flex-start'],
      transition: '.8s cubic-bezier(.4, 0, .6, 1)',
      backfaceVisibility: 'hidden'
    },

    column: {
      flexDirection: 'column',
      width: ['', '', '', '50vw'],
      position: ['', '', '', 'absolute'],
      transform: ['', '', '', 'translateX(-50vw)'],
      height: ['', '', '', '100vh'],
      justifyContent: 'center',
      backgroundColor: ['', '', '', 'dark']
    },

    imageContainer: {
      justifyContent: 'center'
    },
    image: {
      width: '70%',
      maxWidth: '375px',
      height: '100%'
    },

    content: {
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    },

    locationTitle: {
      marginBottom: ['1rem', '', '2rem'],
      fontSize: 8,
      fontWeight: 'bold'
    },

    CTAS: {
      flexWrap: 'wrap',
      justifyContent: 'center',
      a: {
        // variant: 'buttons.multiLocation',
        fontSize: ['14px', '16px'],
        width: 'calc(50% - 25px)',
        margin: ['0px 5px 10px', '0px 12.5px 25px'],
        padding: ['0.75rem'],
        flexGrow: 1,
        backgroundColor: 'primary',
        justifyContent: 'center',
        textAlign: 'center',
        display: 'flex',
        borderRadius: '4px',
        '&:hover': {
          backgroundColor: 'secondary'
        },
        svg: {
          marginRight: '5px'
        }
      }
    },
    CTAButton: {},

    locationArrow: {
      position: 'fixed',
      bottom: ['0', '', '2rem'],
      left: '50%',
      transform: 'translateX(-50%)',
      flexDirection: 'column',
      alignItems: 'center',
      zIndex: '1000',
      svg: {
        fontSize: ['30px', '50px']
      }
    }
  }
}
