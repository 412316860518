export default {
  sideBySideV1a: {
    container: {
      backgroundColor: 'background',
      flexDirection: ['column', '', 'row'],
      alignItems: ['', '', 'stretch'],
      '.lazyload-wrapper': {
        width: ['100%', '', '50%']
      }
    },
    content: {
      flexDirection: 'column',
      padding: [4, '', '', 8],
      width: ['100%', '', '50%'],
      justifyContent: 'center',
      order: ['', '', '2'],
      a: {
        display: 'flex',
        width: 'fit-content'
      }
    },
    subtitle: {
      fontWeight: 6,
      marginBottom: 3
    },
    title: {
      fontSize: 6,
      marginBottom: 3
    },
    text: {
      lineHeight: '1.75'
      // whiteSpace: 'break-spaces',
    },
    image: {
      width: ['100%'],
      height: '100%',
      objectFit: 'cover',
      order: ['', '', '1']
    },
    // ?============================
    // ?========  Buttons  =========
    // ?============================

    ctaButton: {
      variant: 'buttons.primary'
    },
    secondaryCtaButton: {
      variant: 'buttons.secondary'
    }
  }
}
