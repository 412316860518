export default {
  sideBySideV6: {
    container: {
      backgroundColor: 'background',
      flexDirection: ['column', '', 'row'],
      alignItems: ['', '', 'stretch'],
      '.lazyload-wrapper': {
        width: ['100%', '', '50%']
      },

      // slider styles

      '.slick-initialized': {
        height: ['100vw', '', '100%'],
        '.slick-slide > div': {
          overflow: 'hidden',
          position: 'relative',
          height: ['100vw', '80vw', '100%']
        },
        '.slick-list': {
          height: ['100vw', '80vw', '100%']
        },
        '.slick-track': {
          height: ['100vw', '80vw', '100%']
        },
        '.slick-slide img': {
          height: ['100vw', '80vw', '100%'],
          width: '100%',
          position: 'absolute',
          top: '50%',
          left: '50%',
          objectFit: 'cover',
          transform: 'translate(-50%, -50%)'
        },
        '.slick-dots': {
          bottom: '25px'
        },

        '.slick-arrow': {
          backgroundColor: 'background',
          color: 'text',
          height: '35px',
          width: '35px'
        },

        '.slick-dots li button:before': {
          color: 'white',
          fontSize: '10px'
        },
        '.slick-next': {
          right: '5px',
          zIndex: '500'
        },
        '.slick-prev': {
          left: '5px',
          zIndex: '500'
        }
      }
    },
    content: {
      flexDirection: 'column',
      padding: [4, '', '', 8],
      width: ['100%', '', '50%'],
      justifyContent: 'center',
      a: {
        display: 'flex',
        width: 'fit-content'
      }
    },
    subtitle: {
      fontWeight: 6,
      marginBottom: 3
    },
    title: {
      fontSize: 6,
      marginBottom: 3
    },

    ctaButton: {
      padding: 3,
      marginTop: 5,
      border: 'solid 2px',
      borderColor: 'primary',
      color: 'primary',
      width: 'max-content',
      textTransform: 'uppercase',
      fontWeight: 'bold',
      '&:hover': {
        backgroundColor: 'primary',
        color: 'light'
      }
    },

    text: {
      lineHeight: '1.75'
      // whiteSpace: 'break-spaces',
    },
    image: {
      width: ['100%'],
      height: '100%',
      objectFit: 'cover'
    }
  }
}
