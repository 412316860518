// * Component To Render Single Album Gallery.

const variantName = 'galleryV3a'

export default {
  [variantName]: {
    // ?============================
    // ?======  Containers =========
    // ?============================
    wrapper: {
      width: '100%',
      // This is the Lightbox style to make sure its ontop of everything else
      '.ReactModal__Overlay': {
        zIndex: '5000 !important'
      }
    },

    // ?============================
    // ?====  Albums Containers ====
    // ?============================

    // TODO: Check if these Styles Are needed as the albums page isn't used in this component. as it only renders one album. not multiple

    albumsContainer: {
      width: '100%',
      flexWrap: 'wrap',
      justifyContent: 'center'
    },
    albumsCTA: {
      width: ['100%', '48%', '24%'],
      margin: [0, '1%'],
      marginBottom: ['1%'],
      height: '300px',
      cursor: 'pointer',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundPosition: 'center',
      backgroundSize: 'cover'
    },
    albumTitle: {
      color: 'white',
      textShadow: '2px 2px black',
      fontSize: 5,
      textAlign: 'center'
    },

    // ?============================
    // ?=====  Album Container =====
    // ?============================

    albumContainer: {
      flexWrap: 'wrap',
      alignItems: 'stretch',
      margin: '1rem auto',
      justifyContent: 'center',
      padding: 4
    },
    albumImage: {
      width: ['50%', '', '33.33%', '25%'],
      height: ['200px', '', '300px'],
      objectFit: 'cover',
      padding: 1
    }
  }
}
