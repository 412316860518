const variantName = 'hoursV1'

export default {
  [variantName]: {
    // ?============================
    // ?======  Containers =========
    // ?============================
    container: {},
    section: {
      padding: '4rem 1rem',
      maxWidth: ['400px', '', '', '1200px'],
      margin: '0 auto'
    },

    // ?============================
    // ?======  Text Content =======
    // ?============================

    textContent: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },
    title: {
      textAlign: 'center',
      fontSize: '2rem',
      marginBottom: '2rem'
    },
    subtitle: {
      fontSize: '1.25rem',
      marginBottom: '1rem',
      textAlign: 'center'
    },

    text: {
      fontSize: '1rem',
      textAlign: 'center',
      marginBottom: '2rem'
    },

    // ?============================
    // ?=====  Hours Container =====
    // ?============================

    hoursContainer: {
      flexDirection: ['column', '', '', 'row']
    },

    // ?============================
    // ?======  Day Container ======
    // ?============================

    dayContainer: {
      flexWrap: 'wrap',
      marginBottom: 3,
      width: '100%',
      flexDirection: ['', '', '', 'column'],
      alignItems: ['', '', '', 'center']
    },

    dayofWeekText: {
      textTransform: 'capitalize',
      fontWeight: 'bold'
    },

    // ?============================
    // ?=======  Time Blocks =======
    // ?============================

    timeBlocks: {
      flexDirection: ['column']
    },
    timeBlock: {
      width: '100%',
      flexDirection: ['', '', '', 'column'],
      alignItems: ['', '', '', 'center'],
      margin: ['', '', '', '0.25rem 0']
    },

    label: {
      textTransform: 'capitalize',
      fontSize: ['', '', '', '0.8rem']
    },

    time: {
      flewGrow: '1',
      fontSize: ['', '', '', '0.8rem'],
      margin: ['0 0 0 auto', '', '', 'unset']
    },
    closed: {
      textAlign: 'center',
      margin: ['0 0 0 auto', '', '', 'unset'],
      fontSize: ['', '', '', '0.8rem']
    }
  }
}
