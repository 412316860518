import noDataContainer from '../Reuseables/NoDataContainer'
const variantName = 'blogMenu'

export default {
  [variantName]: {
    // ?============================
    // ?======  Containers =========
    // ?============================

    container: {
      padding: ['9rem 0.5rem 0.5rem', '10rem 1rem 1rem'],
      display: ['', '', '', 'flex']
    },

    // ?============================
    // ?========  Filters   ========
    // ?============================

    filters: {
      display: 'flex',
      padding: ['0rem', '', '', '1rem'],
      flexDirection: ['', '', '', 'column'],
      minWidth: '220px',
      borderRight: ['', '', '', '1px solid'],
      marginRight: ['', '', '', '1rem'],
      flexWrap: 'wrap'
    },

    filtersTitle: {
      width: '100%',
      padding: '0.25rem',
      marginBottom: '0.25rem'
    },

    numArticles: {
      fontSize: '0.8rem',
      fontWeight: 'normal',
      padding: '0.25rem',
      margin: '1rem 0rem',
      width: '100%'
    },

    filterLabel: {
      marginBottom: '0.25rem',
      padding: '0.25rem',
      fontSize: '0.8rem'
    },

    orderByContainer: {
      flexGrow: ['1', '', '', '0'],
      minWidth: '100px',
      margin: '0.25rem',
      select: {
        backgroundColor: 'background'
      }
    },
    searchContainer: {
      flexGrow: ['1', '', '', '0'],
      margin: ['0.25rem'],
      color: 'text',
      svg: {
        position: 'absolute',
        right: '0.75rem',
        top: '50%',
        transform: 'translateY(-50%)'
      }
    },
    searchInputContainer: {
      position: 'relative',
      input: {
        '::placeholder': {
          color: 'text'
        }
      }
    },

    sortByContainer: {
      margin: '0.25rem',
      flexGrow: ['1', '', '', '0'],
      select: {
        backgroundColor: 'background'
      }
    },

    filterTagContainer: {
      flexGrow: ['1', '', '', '0'],
      margin: ['0.25rem']
    },
    filterTagDropdown: {
      border: 'solid 1px',
      fontWeight: 'normal',
      padding: '0.6rem',
      borderRadius: '5px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      fontSize: '0.9rem',
      svg: {
        marginLeft: '1rem'
      }
    },

    filterTagList: {
      padding: '1rem 0.5rem',
      position: ['absolute', '', '', 'static'],
      zIndex: '100',
      border: 'solid 1px',
      marginTop: '0.25rem',
      borderRadius: '5px'
    },

    filterTagLabel: {
      textTransform: 'capitalize',
      backgroundColor: 'background',
      svg: {
        marginRight: '5px'
      }
    },

    // ?============================
    // ?======  Article Boxes ======
    // ?============================

    blogsContainer: {
      display: 'flex',
      width: '100%',
      flexDirection: 'column'
    },
    boxesHeading: {
      textTransform: 'uppercase',
      textAlign: 'center',
      borderBottom: '2px solid',
      width: 'fit-content',
      padingBottom: '0.5rem',
      margin: ['2rem auto 2rem', '', '', '2rem auto 2rem']
    },

    boxesContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'stretch',
      width: '100%',
      padding: '0.5rem',

      // ?======  Nth child Article Boxes ======
      // first two boxes
      '> div:nth-of-type(-n+2)': {
        width: ['100%', '100%', 'calc(50% - 1rem)'],
        position: 'relative',
        maxHeight: ['', '', '50vh'],
        '.imageFill': {
          paddingBottom: ['120%', '100%', '100%', '100%', '80%']
        },
        img: {
          filter: 'brightness(0.6)'
        },
        padding: '0rem',
        '.textContent': {
          border: 'none',
          position: 'absolute',
          width: '100%',
          bottom: '0rem',
          color: 'white',
          padding: '0.5rem 1rem',
          backgroundColor: 'transparent',
          '.title': {
            marginBottom: '2rem',
            color: 'white'
          },
          '.subtitle': {
            marginBottom: '1rem',
            color: 'white'
          },
          '.text': {
            display: 'none',
            marginBottom: '1rem'
          },
          '.tagsContainer': {
            marginBottom: '0.25rem'
          },
          '.timeContainer, .author': {
            opacity: '1'
          }
        }
      },
      '> div:nth-of-type(3)': {
        width: '100%',
        maxHeight: ['', '', '50vh'],
        a: {
          display: 'flex',
          flexDirection: ['column', 'column', 'row']
        },
        '.imageContainer': {
          width: ['100%', '', '50%']
        },
        '.textContent': {
          border: 'none',
          width: ['100%', '', '50%'],
          padding: ['1rem', '', '2rem']
        }
      }
    },
    box: {
      width: ['100%', 'calc(50% - 1rem)', 'calc(33.3% - 1rem)', ''],
      // border: "solid 1px black",
      margin: ['1rem 0rem !important', '1rem 0.5rem!important', '1rem 0.5rem!important'],
      padding: '0rem',
      overflow: 'hidden',
      flexGrow: '1',
      boxShadow: '2px 2px 10px grey',
      a: {
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      },
      ':hover': {
        '.textContent': {
          opacity: '0.8'
        },
        img: {
          transform: 'translate(-50%, -50%) scale(1.1)'
        }
      }
    },

    // ?============================
    // ?======  Image Content =======
    // ?============================

    imageContainer: {
      width: '100%',
      position: 'relative',
      overflow: 'hidden'
    },
    imageFill: {
      paddingBottom: '65%'
    },
    image: {
      transition: 'all ease-in-out 0.5s',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)',
      width: '100%',
      height: '100%',
      objectFit: 'cover'
    },

    // ?============================
    // ?======  Text Content =======
    // ?============================
    textContent: {
      padding: '1rem',
      fontSize: '1rem',
      borderTop: '1px solid',
      transition: 'all ease-in-out 0.5s',
      backgroundColor: 'background',
      flexGrow: '1',
      display: 'flex',
      flexDirection: 'column'
    },
    // name: {
    //   fontSize:'1.5em',
    //   marginBottom: '1rem',
    // },
    title: {
      fontSize: ['1.5em', '1.75em'],
      marginBottom: '1rem',
      color: 'primary',
      lineHeight: '1.5'
    },
    subtitle: {
      fontSize: '1em',
      marginBottom: '1rem',
      opacity: '0.85',
      lineHeight: '1.5'
    },
    text: {
      fontSize: '0.85em',
      marginBottom: '1rem',
      flexGrow: '1',
      lineHeight: '1.25'
    },

    author: {
      opacity: '0.6',
      marginBottom: '0.5rem',
      fontSize: '0.9em',
      width: '100%',
      textAlign: 'right'
    },

    timeContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      borderTop: '1px solid',
      opacity: '0.6',
      padding: '0.5rem 0rem 0rem',
      margin: 'auto 0rem 0rem',
      svg: {
        marginRight: '0.5rem'
      }
    },

    readingTime: {
      fontSize: '0.8em',
      margin: '0rem 1rem 0rem 0rem',
      padding: '0.5rem 0rem',
      svg: {
        marginRight: '0.5rem'
      }
    },

    createdAt: {
      fontSize: '0.8em',
      margin: '0rem 1rem 0rem 0rem',
      padding: '0.5rem 0rem',
      svg: {
        marginRight: '0.5rem'
      }
    },

    // ? =====================
    // ? ======= Tags ========
    // ? =====================

    tagsContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      width: '100%',
      marginBottom: '2rem'
    },
    tagPill: {
      margin: '0rem 0.25rem 0.25rem 0rem',
      backgroundColor: '#37393a',
      color: 'white',
      borderRadius: '100px',
      padding: '0.25rem 0.7rem',
      fontSize: '0.7rem',
      fontWeight: 'normal'
    },

    // ?============================
    // ?========  Buttons  =========
    // ?============================

    ctaContainer: {
      a: {
        display: 'flex',
        variant: 'buttons.primary'
      }
    },

    // ?=============================
    // ?====  No Data Container  ====
    // ?=============================

    ...noDataContainer
  }
}
