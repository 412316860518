const variantName = 'contentSectionV3'

export default {
  [variantName]: {
    // ?============================
    // ?======  Containers =========
    // ?============================

    container: {
      padding: '5vh 0vw 0px',
      display: 'flex',
      flexDirection: 'column',
      textAlign: ['', '', 'center'],
      alignItems: ['center'],
      justifyContent: 'flex-start'
    },
    innerContainer: {
      height: '100vh'
    },
    section: {
      // maxWidth: '767px',
      minHeight: '400px',
      margin: '0 auto',
      display: 'flex',
      flexDirection: 'column',
      textAlign: ['', '', 'center'],
      alignItems: ['center'],
      justifyContent: 'center'
    },

    imageContainer: {
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      img: {
        height: '120vh',
        objectFit: 'cover'
      }
    },

    gradient: {
      background: 'linear-gradient(0deg, #060812 0%, rgba(0, 0, 0, 0.75) 50%, rgba(0, 0, 0, 0) 100%)',
      width: '100%',
      height: '50%',
      position: 'absolute',
      bottom: '0px',
      left: '0px'
    },

    imageText: {
      position: 'absolute',
      bottom: '10vh',
      left: '10vw',
      color: 'white',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      textAlign: 'left',
      maxWidth: '767px'
    },

    // ?============================
    // ?======  Text Content =======
    // ?============================

    title: {
      variant: 'text.title',
      fontSize: ['2rem', '2.5rem', '2.5rem', '2.75rem', '3rem'],
      color: 'text'
    },
    subtitle: {
      variant: 'text.title',
      color: 'inherit',
      fontSize: ['2rem', '2.5rem', '2.5rem', '2.75rem', '3rem'],
      marginBottom: '3rem',
      order: 2
    },
    text: {
      variant: 'text.text'
    },

    // ?============================
    // ?========  Buttons  =========
    // ?============================

    ctaButton: {
      variant: 'buttons.primary'
    },
    secondaryCtaButton: {
      variant: 'buttons.secondary'
    }
  }
}
