export default {
  multiLocationsV2: {
    container: {
      minHeight: '100vh',
      flexWrap: 'wrap;',
      position: 'relative',
      justifyContent: 'center',
      flexDirection: 'column',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      a: {
        textDecoration: 'none;'
      }
    },
    logo: {
      width: '100%',
      textAlign: 'center',
      marginBottom: '2rem',
      img: {
        maxWidth: '100%',
        maxHeight: '150px'
      }
    },
    locationsContainer: {
      flexDirection: ['column', 'column', 'row'],
      flexGrow: '1',
      flexWrap: ['wrap', 'wrap', 'wrap-reverse']
    },
    location: {
      flexGrow: '1',
      justifyContent: 'center',
      alignItems: 'center',
      margin: '1.5rem'
    },
    content: {
      textAlign: 'center',
      flexDirection: 'column'
    },
    locationTitle: {
      color: 'light',
      fontWeight: 'bold',
      textTransform: 'uppercase',
      fontSize: '2rem'
    },
    CTAS: {
      flexWrap: 'wrap',
      justifyContent: 'center'
    },

    CTAButton: {
      variant: 'buttons.multiLocation',
      width: 'calc(50% - 10px)',
      margin: '25px 5px',
      transition: 'all ease-in-out 0.3s',
      svg: {
        marginRight: '5px'
      },
      '&:hover': {
        backgroundColor: 'secondary'
      }
    },
    enterSiteButton: {
      margin: '0 16px',
      border: 'solid 1px white',
      backgroundColor: 'unset',
      borderRadius: '5px',
      color: 'light',
      display: 'flex',
      textDecoration: 'none',
      variant: 'buttons.multiLocation',
      transition: 'all ease-in-out 0.3s',
      '&:hover': {
        backgroundColor: 'secondary'
      }
    }
  }
}
