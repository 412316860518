const variantName = 'contentBoxesV1b'

export default {
  [variantName]: {
    // ?============================
    // ?======  Containers =========
    // ?============================

    container: {
      padding: '3rem 1rem'
    },

    // ?============================
    // ?=========  Boxes  ==========
    // ?============================

    contentBoxes: {
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      display: 'flex',
      maxWidth: '1200px',
      margin: 'auto',
      '> a:nth-of-type(even)': {
        height: ['', '', '400px']
      }
    },

    box: {
      height: '300px',
      p: ['0rem 0rem 1rem', '', '1rem'],
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'stretch',
      flexGrow: '1',
      backgroundPosition: 'center',
      transition: 'all ease-in-out 2s',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      width: ['100%', 'calc(50% - 1rem)', 'calc(33.33% - 1rem)', 'calc(20% - 1rem)'],
      '&:hover': {
        backgroundPosition: 'bottom left'
      }
    },

    // ?============================
    // ?========  Images  ==========
    // ?============================

    image: {
      marginBottom: 1,
      objectFit: 'cover',
      width: '100%',
      height: '100%'
    },

    // ?============================
    // ?======  Text Content =======
    // ?============================

    date: {
      marginBottom: 1,
      fontSize: 2,
      paddingX: 2,
      fontWeight: 'bold'
    },
    title: {
      color: 'light',
      padding: 4,
      fontSize: 4,
      display: 'inline-block',
      margin: '0 auto',
      textTransform: 'uppercase',
      transition: 'all ease-in-out 0.5s',
      textAlign: 'right',
      bottom: 1,
      right: 1,
      position: 'absolute'
    },
    subtitle: {
      marginBottom: 1,
      paddingX: 2
    },
    text: {
      marginBottom: 1,
      paddingX: 2
    }
  }
}
