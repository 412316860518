const variantName = 'eventsV3'

export default {
  [variantName]: {
    // ?============================
    // ?======  Containers =========
    // ?============================
    container: {
      padding: '1rem'
    },

    // ?============================
    // ?======  Text Content =======
    // ?============================

    heading: {
      padding: '0.5rem;',
      textAlign: 'center',
      fontWeight: 'bold',
      fontSize: '2rem',
      textTransform: 'uppercase'
    },

    textContent: {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '2rem 1rem 1rem'
    },
    title: {
      fontSize: ['2rem'],
      marginBottom: '2rem'
    },
    subtitle: {
      marginBottom: '1rem'
    },
    text: {},

    // ?============================
    // ?===== Events Container =====
    // ?============================

    eventsContainer: {
      // justifyContent: 'center',
      // flexWrap: 'wrap'
      width: '100%'
    },

    // ?============================
    // ?======  Event Item  ========
    // ?============================

    eventItemContainer: {
      margin: ['3%'],
      // width: ['48%', '31%', '23%', '18%'],
      flexDirection: 'column'
    },

    eventItemImage: {
      minHeight: '250px',
      maxHeight: '300px',
      objectFit: 'cover'
    },

    eventItemContent: {
      flexGrow: '1',
      paddingTop: 2,
      paddingBottom: 2,
      flexDirection: ['column', 'row']
    },

    eventItemTitle: {
      fontSize: 4,
      textTransform: 'capitalize',
      textAlign: 'left',
      fontWeight: 'bolder',
      fontFamily: 'heading'
    },

    eventItemBtn: {
      variant: 'buttons.secondary'
    },

    eventItemDateContainer: {
      textAlign: ['left', 'center'],
      maxWidth: '50px',
      maxHeight: '50px',
      minWidth: 'unset',
      marginRight: [0, 1]
    },

    eventDate: {
      fontSize: 3,
      color: 'secondary',
      fontWeight: 'bolder',
      width: '100%',
      marginBottom: 0,
      fontFamily: 'body'
    },

    eventDaysContainer: {
      marginTop: '0.5rem'
    },

    eventDays: {
      textTransform: 'capitalize',
      fontWeight: 'bold',
      marginBottom: 2
    },

    eventMonth: {
      textTransform: 'uppercase',
      marginBottom: 0,
      width: '100%',
      fontFamily: 'body'
    },

    eventTime: {
      marginBottom: 2
    },
    eventDescription: {
      fontSize: 2
    }
  }
}
