const variantName = 'locationBoxesV3'

export default {
  [variantName]: {
    // ?============================
    // ?======  Containers =========
    // ?============================
    container: {
      justifyContent: 'center',
      alignItems: 'stretch',
      textAlign: 'center',
      flexWrap: 'wrap',
      padding: '2rem 1rem'
    },

    // ?============================
    // ?=== ContentBox/Location ====
    // ?============================
    box: {
      flexDirection: 'column',
      width: ['100%', '50%', '', '33.33%'],
      padding: ['1rem', '', '2rem'],
      a: {
        '&:hover': {
          color: 'primary',
          '&:hover svg path': {
            fill: 'primary'
          }
        },
        svg: {
          path: {
            fill: 'text'
          },
          '&:hover path': {
            fill: 'primary'
          }
        }
      }
    },
    // ?============================
    // ?========  Images  ==========
    // ?============================
    imageContainer: {},

    // ?============================
    // ?=========  Logos  ==========
    // ?============================

    logoImg: {
      maxHeight: '100px'
    },

    // ?============================
    // ?======  Text Content =======
    // ?============================

    title: {
      margin: '1rem 0rem',
      width: '100%'
    },

    // ?============================
    // ?========  Buttons  =========
    // ?============================

    CTAS: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
      a: {
        margin: '0.25rem',
        backgroundColor: 'primary',
        flexGrow: '1',
        padding: '0.5rem',
        background: 'transparent',
        border: 'solid 1px',
        borderColor: 'primary',
        borderRadius: '0px',
        ':hover': {
          color: 'white',
          backgroundColor: 'primary'
        }
      }
    },

    // ?============================
    // ?=========  Widgets  ========
    // ?============================

    contactDetailsContainer: {
      '.text': {
        fontSize: '0.9rem',
        svg: {
          marginRight: '0.5rem',
          path: {
            fill: 'primary'
          }
        }
      }
    },
    socialIconsContainer: {
      padding: '0.5rem',
      a: {
        svg: {
          width: '1.25rem',
          height: '1.25rem',
          margin: '0rem 0.5rem'
        }
      },
      justifyContent: 'center',
      '& > div': {
        height: 'unset',
        justifyContent: 'center'
      }
    }
  }
}
