const variantName = 'locationMapV1'

export default {
  [variantName]: {
    container: {
      width: '100%',
      height: '50vh',
      iframe: {
        width: '100%',
        height: '50vh'
      }
    }
  }
}
