const variantName = 'footerV2'

export default {
  [variantName]: {
    // ?============================
    // ?======  Containers =========
    // ?============================
    container: {
      paddingX: [4, '', '', 10],
      paddingY: 8,
      backgroundColor: 'primary',
      flexDirection: ['column', 'column', 'row', 'row'],
      justifyContent: ['center', '', '', 'flex-end'],
      alignItems: 'center',
      // ?== Nested Elements ==
      '.contactDetails-container': {
        justifyContent: 'center',
        textAlign: 'center'
      },
      '.multiButtonContainer a': {
        margin: '0.25rem'
      },
      '.address': {
        textAlign: ['', '', '', 'right']
      },
      '.gonationLogo': {
        filter: 'brightness(0) invert(1)'
      },
      '.socialIconsContainer svg': {
        width: '25px',
        height: '25px',
        marginRight: '0.5rem'
      }
    },

    column: {
      flexDirection: 'column',
      padding: 2,
      width: ['100%', '100%', '60%'],
      alignItems: ['center', 'center', 'center', 'flex-end'],
      // ?== Nested Elements ==
      div: {
        justifyContent: ['', '', '', 'flex-end'],
        margin: '0.5rem',
        alignItems: ['', '', '', 'flex-end']
      }
    },

    // ?============================
    // ?=========  Logos  ==========
    // ?============================

    logo: {
      maxHeight: '150px',
      margin: ['0 auto 1rem']
    },
    logoColumn: {
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      flexGrow: '1'
    },

    // ?============================
    // ?=========  Widgets  ========
    // ?============================

    // ! Considering Removing Pop Contents Feature and using this in shadowing only
    popUpContentBoxesContainer: {
      margin: '0.5rem',
      // ?== Psuedo Elements ==
      '& > button': {
        padding: '1',
        fontSize: '0.8rem'
      }
    }
  }
}
