const variantName = 'footerV7'

export default {
  [variantName]: {
    // ?============================
    // ?======  Containers =========
    // ?============================

    container: {
      display: ['flex', '', 'grid'],
      flexDirection: ['column', '', 'unset'],
      gridTemplateColumns: 'repeat(3, 1fr)',
      gridTemplateRows: 'repeat(3, 1fr)',
      gridColumnGap: '0px',
      gridRowGap: '0px'
    },

    logoContainer: {
      borderTop: ['1px solid', '', 'none'],
      display: 'grid',
      gridArea: '1 / 1 / 4 / 2',
      p: ['2rem', '', '2rem 4rem'],
      borderRight: '1px solid',
      borderColor: '#515151',
      order: '2',
      '.logo': {
        width: '100%',
        height: 'auto',
        maxHeight: 'unset',
        maxWidth: '240px',
        marginBottom: 'auto'
      },
      '.gonationLogo': {
        mb: '0.25rem',
        marginTop: 'auto',
        '.poweredByText': {
          justifyContent: 'flex-start'
        }
      }
    },
    logoContainerFooter: {
      padding: ['2rem 0rem 6rem', '', '', '2rem 0rem'],
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      alignItems: 'flex-start',
      '.gonationLogo': {
        a: {
          justifyContent: 'flex-start'
        }
      }
    },
    buttonsContainer: {
      display: 'grid',
      gridArea: '1 / 2 / 2 / 4',
      p: ['2rem', '', '2rem 4rem'],
      borderBottom: '1px solid',
      borderColor: '#515151',
      '.multiButtonContainer': {
        width: '100%',
        margin: '0rem',
        justifyContent: 'flex-start',
        alignItems: 'center',
        a: {
          height: ' fit-content',
          variant: 'text.title',
          border: 'none',
          padding: '0rem',
          margin: '0.5rem',
          backgroundColor: 'transparent',
          ':hover': {
            backgroundColor: 'transparent',
            color: 'primary'
          }
        }
      }
    },

    heading: {
      variant: 'text.title',
      color: 'primary',
      order: 'unset',
      mb: '2rem',
      fontSize: ['1.2rem', '1.2rem', '1.2rem', '1.2rem', '1.2rem']
    },

    contactContainer: {
      display: 'grid',
      gridArea: '2 / 2 / 4 / 4',
      p: ['2rem', '', '2rem 4rem'],
      '.contactDetails-container': {
        '.text': {
          svg: {
            mr: '1rem'
          },
          margin: '0rem 0rem 1.5rem'
        }
      },
      '.socialContainer': {
        margin: ['1rem 0rem', '', '', '2rem 0rem'],
        svg: {
          padding: '0rem',
          margin: '0rem',
          marginRight: '1.5rem',
          width: '35px',
          height: '35px',
          color: 'inherit'
        }
      }
    },
    // ?============================
    // ?=========  Logos  ==========
    // ?============================

    logo: {
      maxHeight: '150px'
    },

    // ?============================
    // ?=========  Hours  ==========
    // ?============================

    innerHoursContainer: {
      mt: '2rem',
      mb: '1rem'
    },
    time: {
      margin: '0rem 0rem 1rem'
    },

    typelabel: {
      fontSize: '1.2rem'
    }
  }
}
