export default {
  page: {
    container: {
      padding: ['1rem', '', '2rem', '3rem'],
      maxWidth: '1200px',
      margin: '0rem auto'
    },
    section: {
      display: 'flex',
      flexDirection: 'column'
    },
    title: {
      variant: 'customVariants.title'
    },
    subtitle: {
      variant: 'customVariants.subtitle'
    },
    text: {
      variant: 'customVariants.text'
    },
    ctaButton: {
      variant: 'buttons.primary'
    },
    secondaryCtaButton: {
      variant: 'buttons.secondary'
    }
  }
}
