const variantName = 'galleryV1'

export default {
  [variantName]: {
    // ?============================
    // ?======  Containers =========
    // ?============================

    container: {
      padding: '3rem 1rem',
      width: '100%'
    },
    wrapper: {
      width: '100%'
    },

    // ?============================
    // ?====  Albums Containers ====
    // ?============================

    albumsContainer: {
      width: '100%',
      flexWrap: 'wrap',
      justifyContent: 'center'
    },

    albumsCTA: {
      width: ['100%', '48%', '24%'],
      margin: [0, '1%'],
      marginBottom: ['1%'],
      height: '300px',
      cursor: 'pointer',
      backgroundSize: 'cover',
      alignItems: 'center',
      justifyContent: 'center'
    },
    albumTitle: {
      color: 'white',
      textShadow: '2px 2px black',
      fontSize: 5,
      textAlign: 'center'
    },

    // ?============================
    // ?=====  Album Container =====
    // ?============================

    albumContainer: {
      flexWrap: 'wrap',
      alignItems: 'stretch',
      margin: '1rem auto',
      justifyContent: 'center'
    },
    albumImage: {
      width: ['50%', '33%', '25%', '20%'],
      objectFit: 'cover',
      height: '200px',
      padding: 1
    }
  }
}
