const variantName = 'heroV3a'

export default {
  [variantName]: {
    // ?============================
    // ?======  Containers =========
    // ?============================

    hero: {
      height: '100vh',
      width: '100%',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative'
    },

    // ?============================
    // ?======  Box Content ========
    // ?============================

    content: {
      padding: '2rem',
      flexDirection: 'column',
      color: 'white',
      textAlign: 'center',
      position: 'absolute',
      top: '50%',
      left: '50%',
      objectFit: 'cover',
      transform: 'translate(-50%, -50%)',
      h1: {
        fontSize: '2rem',
        marginTop: '0px',
        marginBottom: 1
      },
      h2: {
        fontSize: '1rem',
        marginTop: '0px',
        marginBottom: 4
      },
      logo: {
        marginBottom: 4
      },
      button: {
        variant: 'buttons.multiLocation'
      }
    }
  }
}
