const variantName = 'navigationV8'

export default {
  [variantName]: {
    // ?============================
    // ?======== Container =========
    // ?============================
    container: {
      position: 'fixed',
      width: '100%',
      top: '0px',
      left: '0px',
      zIndex: '1000',
      boxSizing: 'border-box',
      // height: ['75px', '75px', '75px', ''],
      padding: ['2rem 0.5rem', '', '', '3rem'],
      display: 'flex',
      transition: 'all ease-in-out 0.5s',
      alignItems: 'center',
      justifyContent: 'flex-end',
      background: 'linear-gradient(180deg, rgba(0,0,0,0.75) 0%, rgba(0,0,0,0.33) 50%, rgba(255,255,255,0) 100%);'
    },

    containerScrolled: {
      variant: `${variantName}.container`,
      background: 'unset',
      backgroundColor: 'primary',
      padding: ['1rem 0.5rem', '', '', '1rem'],
      height: ['75px', '', '', 'auto']
    },

    // ?============================
    // ?=======  Nav Logos =========
    // ?============================

    logo: {
      position: ['absolute', '', '', 'unset'],
      top: ['1rem', '', '', '0px'],
      left: ['50%', '', '', '4'],
      transform: ['translateX(-50%)', '', '', 'unset'],
      alignSelf: 'top',
      zIndex: '2000',
      margin: ['', '', '', '0 auto 0 0'],
      img: {
        maxHeight: ['65px', '85px', '100px'],
        maxWidth: '200px'
      }
    },
    logoScrolled: {
      variant: `${variantName}.logo`,
      img: {
        maxHeight: '75px',
        maxWidth: '200px'
      },
      top: ['unset']
    },

    // ?============================
    // ?=======  Hamburger =========
    // ?============================

    hamburger: {
      position: 'relative',
      display: ['flex', 'flex', 'flex', ''],
      zIndex: '2000',
      alignSelf: 'center',
      flexDirection: 'column',
      justifyContent: 'space-between',
      width: '35px',
      height: '25px',
      cursor: 'pointer',
      margin: ['0 0.5rem 0 auto', '', '', '0 0.5rem 0rem 1rem'],
      div: {
        alignSelf: 'flex-end',
        transition: 'all ease-in-out 0.3s',
        height: '4px',
        width: '100%',
        backgroundColor: 'secondary',
        borderRadius: '10px'
      }
    },
    hamburgerOpen: {
      position: 'relative',
      display: ['flex', 'flex', 'flex', ''],
      zIndex: '2000',
      alignSelf: 'center',
      flexDirection: 'column',
      justifyContent: 'center',
      width: '35px',
      height: '25px',
      cursor: 'pointer',
      margin: ['0 0.5rem 0 auto', '', '', '0 0.5rem'],
      div: {
        position: 'absolute',
        transition: 'all ease-in-out 0.3s',
        height: '4px',
        width: '100%',
        backgroundColor: 'text',
        boxShadow: 'none',
        borderRadius: '10px'
      },
      '& > :nth-child(1)': {
        transform: 'rotate(135deg) '
      },
      '& > :nth-child(2)': {
        transform: 'rotate(-135deg)'
      }
    },

    // ?============================
    // ?========  Nav Menu =========
    // ?============================

    navBlock: {
      display: 'flex',
      backgroundColor: 'rgba(255, 255, 255, 0.7)',
      position: 'absolute',
      margin: '0rem',
      zIndex: '500',
      transition: 'all ease-in-out 0.5s',
      top: '0rem',
      right: '-200%',
      width: '100%',
      height: '100vh'
    },

    navMenu: {
      position: ['absolute', 'absolute', 'absolute', 'absolute'],
      margin: '0px',
      boxSizing: 'border-box',
      justifyContent: ['center', '', '', ''],
      height: ['100vh', '100vh', '100vh', '100vh'],
      backgroundColor: ['primary', 'primary', 'primary', 'primary'],
      top: '0px',
      zIndex: '1500',
      width: ['70%', '50%', '50%', '30%'],
      minWidth: '250px',
      right: '-150%',
      transition: 'all ease-in-out 0.5s',
      padding: ['2rem', '', '', '1rem'],
      alignItems: ['flex-start', '', '', ''],
      display: 'flex',
      flexDirection: ['column', 'column', 'column', 'column'],
      overflow: 'visible',
      listStyleType: 'none',

      // dropdown Stlyes in the nav menu
      '.navItemDropdownMenu': {
        position: 'static',
        transition: 'all ease-in-out 0.5s',
        '.navItem': {
          a: {
            fontSize: '0.9rem'
          }
        }
      }
    },

    navMenuOpen: {
      variant: `${variantName}.navMenu`,
      right: '0%'
    },

    navItem: {
      marginBottom: ['0.5rem', '0.75rem', '1rem'],
      zIndex: '100',
      marginLeft: ['0px', '', '0px'],
      margin: ['0rem 0rem 0.5rem', '0rem 0rem 0.75rem', '0rem 0rem 1rem', '0.75rem 1rem 0.75rem 0rem'],
      listStyleType: 'none',
      a: {
        fontSize: ['1rem', '1.25rem', '1.5rem', '1.25rem'],
        textTransform: 'uppercase',
        fontWeight: '400',
        // color: 'light',
        letterSpacing: '2px',
        textDecoration: 'none',
        ':hover': {
          color: 'secondary'
        }
      }
    },

    smallNavMenu: {
      display: ['none!important', '', '', 'flex !important']
    },

    // ?==========================
    // ?====  Dropdown Styles ====
    // ?==========================

    navItemDropdown: {
      alignItems: 'inherit',
      fontFamily: 'inherit',
      justifyContent: 'inherit',
      position: 'relative',
      svg: {
        marginLeft: '0.5rem'
      }
    },

    subNavItemDropdown: {
      variant: `${variantName}.navItem`,
      padding: '0.5rem',
      margin: ['0rem', '0rem', '0rem', '0rem'],
      whiteSpace: 'nowrap',
      a: {
        fontSize: '0.85rem'
      }
    },

    navItemDropdownMenu: {
      padding: '0.5rem',
      textShadow: '0px 0px 30px rgb(122 122 122 / 79%)',
      position: ['', '', '', 'absolute'],
      display: 'flex',
      alignItems: 'inherit',
      justifyContent: 'inherit',
      flexDirection: 'column',
      width: 'auto',
      backgroundColor: 'primary',
      zIndex: '100',
      '.navItem': {
        ':hover': {
          color: 'light',
          opacity: '0.8'
        }
      }
    },

    // ?============================
    // ?====  Extra Nav Widgets ====
    // ?============================

    socialIconsContainer: {
      margin: ['1rem 0rem', '', '', ''],
      svg: {
        marginRight: '0.5rem',
        width: '30px',
        height: '30px'
      }
    },
    phoneContainer: {
      margin: ['1rem 0rem 0rem', '', '', '1.5rem']
    },

    mobileLocationSwitcher: {
      display: ['', '', '', 'none']
    },

    locationSwitcher: {
      '.button': {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        margin: ['0rem', '0.5rem'],
        svg: {
          marginRight: ['0.5rem']
        }
      }
    }
  }
}
