const variantName = 'contactDetails'

export default {
  [variantName]: {
    container: {
      flexDirection: 'column'
    },
    text: {
      fontSize: 4,
      textAlign: 'inherit',
      margin: 2,
      color: 'inherit',
      a: {
        color: 'inherit',
        '&:hover': {
          filter: 'brightness(0.7)'
        }
      }
    }
  }
}
