const variantName = 'footerV4'

export default {
  [variantName]: {
    // ?============================
    // ?======  Containers =========
    // ?============================

    container: {
      paddingX: [4, '', '', 4],
      paddingY: 10,
      backgroundColor: 'background',
      flexDirection: ['column', '', '', 'row'],
      flexWrap: 'wrap',
      justifyContent: ['', '', '', 'center'],
      alignItems: 'center',
      maxWidth: '1200px',
      margin: '0 auto',
      // ?== Nested Elements ==
      '.multiButtonContainer': {
        margin: '1.5rem 0rem',
        width: '100%',
        a: {
          color: 'text',
          margin: '0.25rem',
          borderRadius: '100px'
        }
      },
      '.contactDetails-container': {
        textAlign: 'center'
      },
      '.gonationLogo': {
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        svg: {
          height: '1.25rem',
          width: 'auto'
        }
      },
      '.socialContainer': {
        margin: '1rem',
        svg: {
          marginRight: '0.5rem',
          width: '25px',
          height: '25px'
        }
      }
    },

    column: {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: ['center', '', '', 'center'],
      width: ['', '', '', '50%'],
      textAlign: 'left',
      marginBottom: ['', '', '', '1rem']
    },

    // ?============================
    // ?=========  Logos  ==========
    // ?============================

    logo: {
      maxHeight: '150px'
    },

    // ?============================
    // ?======  Bottom Bar  ========
    // ?============================

    bottomBar: {
      width: '100%',
      flexDirection: ['column', '', '', 'row'],
      alignItems: 'center',
      justifyContent: ['center', '', '', 'space-between'],
      borderTop: ['4px solid'],
      borderColor: ['primary'],
      borderRadius: '10px',
      marginTop: ['1rem'],
      paddingTop: '1rem',
      margin: ['1rem 0rem 0rem', '', '', '1rem 2.5rem']
    },

    // ?============================
    // ?=========  Widgets  ========
    // ?============================

    quote: {
      width: ['', '', '', '50%'],
      margin: '1rem auto',
      fontSize: '2rem',
      fontStyle: 'italic',
      maxWidth: '600px',
      textAlign: ['center', '', '', 'left'],
      padding: '1rem'
    },

    poweredBy: {
      margin: '1rem 0rem 0rem',
      '.gonationLogo': {
        filter: 'brightness(0) invert(1)'
      }
    },
    poweredByText: {
      fontSize: '1.25rem'
    },

    // ! Considering Removing Pop Contents Feature and using this in shadowing only
    popUpContentBoxesContainer: {
      margin: '0.5rem',
      '& > button': {
        padding: '1',
        fontSize: '0.8rem'
      }
    }
  }
}
