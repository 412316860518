const variantName = 'footerV1'

export default {
  [variantName]: {
    // ?============================
    // ?======  Containers =========
    // ?============================
    container: {
      paddingX: 4,
      paddingTop: 8,
      paddingBottom: [10, 10, 8],
      backgroundColor: 'secondary',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',

      // ?== Nested Elements ==
      '.gonationLogo': {
        filter: 'brightness(0) invert(1)'
      },
      '.copyright': {
        marginBottom: '0.5rem'
      }
    },

    // ?============================
    // ?=========  Logos  ==========
    // ?============================

    logo: {
      maxHeight: '150px',
      marginBottom: 4
    }
  }
}
