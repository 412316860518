export default {
  shoutSectionV5: {
    container: {
      width: '100%',
      flexDirection: ['column', '', 'row'],
      fontSize: [4, 4, 5, 6],
      justifyContent: 'center'
    },

    // ? ==================================
    // ? ======== Column Styles ===========
    // ? ==================================

    column: {
      flexDirection: 'column',
      width: ['100%', '', '30%']
    },

    title: {
      fontSize: ['2rem', '', '3rem'],
      padding: '1rem',
      textAlign: 'left',
      maxWidth: '400px',
      width: '100%',
      order: ['', '', '1']
    },

    imageContainer: {
      position: 'relative',
      width: '100%',
      order: ['', '', '2'],
      maxWidth: ['', '', '300px'],
      img: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        objectFit: 'cover'
      }
    },
    imageFill: {
      paddingBottom: '70%'
    },

    // ? ==================================
    // ? ======= Content Box Styles =======
    // ? ==================================

    content: {
      order: '2',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      padding: ['0.5rem 1rem', '', '1rem 2rem'],
      color: 'text',
      width: ['100%', '', '65%']
    },

    text: {
      fontSize: '1em',
      width: '100%',
      marginBottom: '2rem'
    },
    date: {
      fontSize: '0.75em',
      width: '100%',
      marginBottom: '2rem',
      opacity: '0.8'
      // color: 'primary',
    },

    shoutCTAS: {
      flexWrap: 'wrap',
      marginBottom: '0.5rem',
      transition: 'all ease-in-out 0.3s'
    },
    shoutCTA: {
      variant: 'buttons.primary'
    }
  }
}
