export default {
  multiLocationsV4: {
    container: {
      flexWrap: 'wrap;',
      position: 'relative',
      justifyContent: 'space-between',
      flexDirection: 'column',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      margin: '1rem',
      height: 'calc(100vh - 2rem)',
      minHeight: 'unset',
      a: {
        textDecoration: 'none;'
      }
    },
    logo: {
      width: '100%',
      textAlign: 'center',
      marginBottom: '2rem',
      img: {
        maxWidth: '100%',
        maxHeight: '150px'
      }
    },
    locationsContainer: {
      flexDirection: ['row', 'row', 'row'],
      flexWrap: ['wrap', 'wrap', 'wrap'],
      justifyContent: 'center'
    },
    location: {
      justifyContent: 'center',
      alignItems: 'center',
      width: '50%',
      padding: '0.5rem'
    },
    content: {
      textAlign: 'center',
      flexDirection: 'column',
      flexGrow: '1'
    },
    locationTitle: {
      fontWeight: 'bold',
      textTransform: 'uppercase',
      fontSize: ['1.25rem', '', '1.5rem'],
      padding: ['0.5rem', '0.75rem 0.5rem'],
      backgroundColor: 'rgba(230, 230, 210, 0.8)',
      transition: 'all ease-in-out 0.4s',
      ':hover': {
        backgroundColor: 'primary',
        opacity: '0.9',
        color: 'white'
      }
    },
    CTAS: {
      flexWrap: 'wrap',
      justifyContent: 'center'
    },

    CTAButton: {
      variant: 'buttons.multiLocation',
      width: 'calc(50% - 10px)',
      margin: '5px 5px',
      transition: 'all ease-in-out 0.3s',
      padding: ['0.5rem', '0.75rem'],
      '&:hover': {
        backgroundColor: 'secondary'
      }
    },
    CTAButtonText: {
      display: ['none', '', 'inline'],
      marginLeft: '5px'
    }
  }
}
