const variantName = 'multiButtonsV2'

export default {
  [variantName]: {
    container: {
      flexWrap: 'wrap',
      margin: 3,
      justifyContent: 'center',
      flexDirection: ['column', 'column', 'row'],
      // ?== Nested Elements ==
      // TODO: change a to a variantName and give it is own variant rather than nested
      a: {
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        justifyContent: 'center',
        padding: '0.5rem 1rem',
        color: 'light',
        textDecoration: 'none',
        margin: 2,
        fontSize: 5,
        textTransform: 'Capitalize',
        transition: 'all ease-in-out 0.5s',
        // ?== Psuedo Elements ==
        '&:hover': {
          color: 'dark'
        }
      }
    }
  }
}
