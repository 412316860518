// ? ===================================================
// ? =============  3rd Party Imports  =================
// ? ===================================================

import React, { createContext, useReducer } from 'react'

// ? ===================================================
// ? ===============  Local Imports  ===================
// ? ===================================================

// ? ===================================================
// ? ===============  Default State  ===================
// ? ===================================================

const initialState = {
  modal: {
    modalOpen: false,
    title: '',
    subtitle: '',
    text: '',
    html: ''
  },

  lightBox: {
    isOpen: false,
    uri: '',
    caption: ''
  },
  siteData: {},
  accessibilityWidget: {
    active: false,
    CTAWidget: false,
    containerStyles: null,
    position: ''
  },
  loading: true
}

// ? ===================================================
// ? ==============  Context Creation  =================
// ? ===================================================

const store = createContext(initialState)
const { Provider } = store

const ContextProvider = ({ children }) => {
  // all states are here and have their own reducer which allows multiple functions
  const [globalState, dispatch] = useReducer((state, action) => {
    // ? ===================================================
    // ? =================  Actions  =======================
    // ? ===================================================

    switch (action.type) {
      // ? === SETTING SITE DATA ===

      case 'SET_SITE_DATA':
        const sitedata = action.data
        const accessibilitySettings = sitedata?.configData?.siteSettings?.accessibilityWidget
        return {
          ...state,
          siteData: {
            ...sitedata
          },
          accessibilityWidget: {
            ...accessibilitySettings
          },
          loading: false
        }

      // ? === MODAL ACTIONS ===

      case 'OPEN_MODAL':
        return {
          ...state,
          modal: {
            modalOpen: true,
            ...action.data
          }
        }

      case 'CLOSE_MODAL':
        return {
          ...state,
          modal: {
            ...initialState.modal
          }
        }

      // ? === LightBox ACTIONS ===

      case 'OPEN_LIGHTBOX':
        return {
          ...state,
          lightBox: {
            isOpen: true,
            ...action.data
          }
        }
      case 'CLOSE_LIGHTBOX':
        return {
          ...state,
          lightBox: {
            ...initialState.lightBox
          }
        }

      // ? === DEFAULT ERROR ACTIONS ===

      default:
        alert('Error - No Action Could Be Found')
        throw new Error()
    }
  }, initialState)

  // ? ===================================================
  // ? =================  Effects  =======================
  // ? ===================================================

  // ? ============  Homepage Effects  ===================

  // ? ============  Generic Effects  ===================

  // useEffect(() => {
  //   if (globalState.modal?.modalOpen) {

  //   }
  //   return () => {};
  // }, [globalState.modal?.modalOpen]);

  // ? ============  Return  JSX  ===================

  return <Provider value={{ globalState, dispatch }}>{children}</Provider>
}

export { store, ContextProvider }
